import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-297d809a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facet-panel facet-panel-keyword" }
const _hoisted_2 = { class: "facet-panel-header has-background-modest" }
const _hoisted_3 = ["aria-expanded", "hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_KeywordSearch = _resolveComponent("KeywordSearch")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createVNode(_component_CloserController, {
          modelValue: _ctx.state.ariaSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.ariaSelected) = $event)),
          class: "facet-panel-title has-text-widget-title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('label.imageSearchResultPage.titleSearch')), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", {
      class: "facet-panel-items has-background-contents",
      id: "facet-panel-publish-year",
      role: "tabpanel",
      "aria-expanded": _ctx.state.ariaSelected,
      hidden: !_ctx.state.ariaSelected
    }, [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.search()), ["prevent"]))
      }, [
        _createVNode(_component_KeywordSearch, {
          modelValue: _ctx.query.keyword,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query.keyword) = $event)),
          onSearch: _ctx.search,
          placeholder: _ctx.$t('label.keyword'),
          class: "facet-item-keyword",
          label: _ctx.$t('label.keyword')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppButton, {
              styled: "is-none-text",
              type: "submit",
              label: _ctx.$t('parts.search')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["modelValue", "onSearch", "placeholder", "label"])
      ], 32)
    ], 8, _hoisted_3)
  ]))
}
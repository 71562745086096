import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78a9e5bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facet-panel facet-panel-exclude-item" }
const _hoisted_2 = { class: "facet-panel-header has-background-modest" }
const _hoisted_3 = ["aria-labelledby", "aria-expanded", "hidden"]
const _hoisted_4 = { class: "facet-panel-label level" }
const _hoisted_5 = { class: "level-left" }
const _hoisted_6 = { class: "facet-panel-footer level-center is-mobile" }
const _hoisted_7 = { type: "submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createVNode(_component_CloserController, {
          modelValue: _ctx.state.ariaSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.ariaSelected) = $event)),
          class: "facet-panel-title has-text-widget-title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("label.excludeItem")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("ul", {
      class: "facet-panel-items has-background-contents",
      role: "tabpanel",
      "aria-labelledby": `tab${_ctx.facet + 1}`,
      "aria-expanded": _ctx.state.ariaSelected,
      hidden: !_ctx.state.ariaSelected
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.removeItems, (item, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          class: "has-text-normal is-mobile all-panel"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(item.title), 1),
            _createVNode(_component_ButtonIcon, {
              onClick: ($event: any) => (_ctx.deleteRemoveItem(item.pid)),
              onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.deleteRemoveItem(item.pid)), ["prevent"]), ["space","enter"]),
              icon: "minus-circle",
              styled: "is-none",
              "aria-label": `${item.title} ${_ctx.$t('message.excludeItem')}`,
              class: "has-text-modest level-right facet-panel-items-unexclude-button"
            }, null, 8, ["onClick", "onKeydown", "aria-label"])
          ])
        ]))
      }), 128))
    ], 8, _hoisted_3),
    _createElementVNode("form", null, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppButton, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.clear()), ["prevent"])),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.clear()), ["prevent"]), ["space","enter"])),
          styled: "is-none",
          class: "has-text-link",
          id: "facet-panel-clear-exclude-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('label.clearExclude')), 1)
          ]),
          _: 1
        }),
        _withDirectives(_createElementVNode("input", _hoisted_7, null, 512), [
          [_vShow, false]
        ])
      ])
    ])
  ]))
}
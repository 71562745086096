interface Master {[key: string]: {ja: string, en: string}}

const ndcDivisionLabel: Master = {
  0: { ja: '総記', en: 'General works' },
  1: { ja: '哲学', en: 'philosophy' },
  2: { ja: '歴史', en: 'General history' },
  3: { ja: '社会科学', en: 'Social sciences' },
  4: { ja: '自然科学', en: 'Natural science' },
  5: { ja: '技術', en: 'Technology. Engineering' },
  6: { ja: '産業', en: 'Industry and commerce' },
  7: { ja: '芸術', en: 'The arts. Fine arts' },
  8: { ja: '言語', en: 'Language' },
  9: { ja: '文学', en: 'Literature' },
  '00': { ja: '総記', en: '総記' },
  '01': { ja: '図書館、図書館情報学', en: '図書館、図書館情報学' },
  '02': { ja: '図書、書誌学', en: '図書、書誌学' },
  '03': { ja: '百科事典、用語索引', en: '百科事典、用語索引' },
  '04': { ja: '一般論文集、一般講演集、雑著', en: '一般論文集、一般講演集、雑著' },
  '05': { ja: '逐次刊行物、一般年鑑', en: '逐次刊行物、一般年鑑' },
  '06': { ja: '団体、博物館', en: '団体、博物館' },
  '07': { ja: 'ジャーナリズム、新聞', en: 'ジャーナリズム、新聞' },
  '08': { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  '09': { ja: '貴重書、郷土資料、その他の特別コレクション', en: '貴重書、郷土資料、その他の特別コレクション' },
  10: { ja: '哲学', en: '哲学' },
  11: { ja: '哲学各論', en: '哲学各論' },
  12: { ja: '東洋思想', en: '東洋思想' },
  13: { ja: '西洋哲学', en: '西洋哲学' },
  14: { ja: '心理学', en: '心理学' },
  15: { ja: '倫理学、道徳', en: '倫理学、道徳' },
  16: { ja: '宗教', en: '宗教' },
  17: { ja: '神道', en: '神道' },
  18: { ja: '仏教', en: '仏教' },
  19: { ja: 'キリスト教、ユダヤ教', en: 'キリスト教、ユダヤ教' },
  20: { ja: '歴史、世界史、文化史', en: '歴史、世界史、文化史' },
  21: { ja: '日本史', en: '日本史' },
  22: { ja: 'アジア史、東洋史', en: 'アジア史、東洋史' },
  23: { ja: 'ヨーロッパ史、西洋史', en: 'ヨーロッパ史、西洋史' },
  24: { ja: 'アフリカ史', en: 'アフリカ史' },
  25: { ja: '北アメリカ史', en: '北アメリカ史' },
  26: { ja: '南アメリカ史', en: '南アメリカ史' },
  27: { ja: 'オセアニア史、両極地方史', en: 'オセアニア史、両極地方史' },
  28: { ja: '伝記', en: '伝記' },
  29: { ja: '地理、地誌、紀行', en: '地理、地誌、紀行' },
  30: { ja: '社会科学', en: '社会科学' },
  31: { ja: '政治', en: '政治' },
  32: { ja: '法律', en: '法律' },
  33: { ja: '経済', en: '経済' },
  34: { ja: '財政', en: '財政' },
  35: { ja: '統計', en: '統計' },
  36: { ja: '社会', en: '社会' },
  37: { ja: '教育', en: '教育' },
  38: { ja: '風俗習慣、民俗学、民族学', en: '風俗習慣、民俗学、民族学' },
  39: { ja: '国防、軍事', en: '国防、軍事' },
  40: { ja: '自然科学', en: '自然科学' },
  41: { ja: '数学', en: '数学' },
  42: { ja: '物理学', en: '物理学' },
  43: { ja: '化学', en: '化学' },
  44: { ja: '天文学、宇宙科学', en: '天文学、宇宙科学' },
  45: { ja: '地球科学、地学', en: '地球科学、地学' },
  46: { ja: '生物科学、一般生物学', en: '生物科学、一般生物学' },
  47: { ja: '植物学', en: '植物学' },
  48: { ja: '動物学', en: '動物学' },
  49: { ja: '医学、薬学', en: '医学、薬学' },
  50: { ja: '技術、工学', en: '技術、工学' },
  51: { ja: '建設工学、土木工学', en: '建設工学、土木工学' },
  52: { ja: '建築学', en: '建築学' },
  53: { ja: '機械工学、原子力工学', en: '機械工学、原子力工学' },
  54: { ja: '電気工学', en: '電気工学' },
  55: { ja: '海洋工学、船舶工学、兵器、軍事工学', en: '海洋工学、船舶工学、兵器、軍事工学' },
  56: { ja: '金属工学、鉱山工学', en: '金属工学、鉱山工学' },
  57: { ja: '化学工業', en: '化学工業' },
  58: { ja: '製造工業', en: '製造工業' },
  59: { ja: '家政学、生活科学', en: '家政学、生活科学' },
  60: { ja: '産業', en: '産業' },
  61: { ja: '農業', en: '農業' },
  62: { ja: '園芸、造園', en: '園芸、造園' },
  63: { ja: '蚕糸業', en: '蚕糸業' },
  64: { ja: '畜産業、獣医学', en: '畜産業、獣医学' },
  65: { ja: '林業、狩猟', en: '林業、狩猟' },
  66: { ja: '水産業', en: '水産業' },
  67: { ja: '商業', en: '商業' },
  68: { ja: '運輸、交通、観光事業', en: '運輸、交通、観光事業' },
  69: { ja: '通信事業', en: '通信事業' },
  70: { ja: '芸術、美術', en: '芸術、美術' },
  71: { ja: '彫刻、オブジェ', en: '彫刻、オブジェ' },
  72: { ja: '絵画、書、書道', en: '絵画、書、書道' },
  73: { ja: '版画、印章、篆刻、印譜', en: '版画、印章、篆刻、印譜' },
  74: { ja: '写真、印刷', en: '写真、印刷' },
  75: { ja: '工芸', en: '工芸' },
  76: { ja: '音楽、舞踊、バレエ', en: '音楽、舞踊、バレエ' },
  77: { ja: '演劇、映画、大衆芸能', en: '演劇、映画、大衆芸能' },
  78: { ja: 'スポーツ、体育', en: 'スポーツ、体育' },
  79: { ja: '諸芸、娯楽', en: '諸芸、娯楽' },
  80: { ja: '言語', en: '言語' },
  81: { ja: '日本語', en: '日本語' },
  82: { ja: '中国語、その他の東洋の諸言語', en: '中国語、その他の東洋の諸言語' },
  83: { ja: '英語', en: '英語' },
  84: { ja: 'ドイツ語、その他のゲルマン諸語', en: 'ドイツ語、その他のゲルマン諸語' },
  85: { ja: 'フランス語、プロバンス語', en: 'フランス語、プロバンス語' },
  86: { ja: 'スペイン語、ポルトガル語', en: 'スペイン語、ポルトガル語' },
  87: { ja: 'イタリア語、その他のロマンス諸語', en: 'イタリア語、その他のロマンス諸語' },
  88: { ja: 'ロシア語、その他のスラブ諸語', en: 'ロシア語、その他のスラブ諸語' },
  89: { ja: 'その他の諸言語', en: 'その他の諸言語' },
  90: { ja: '文学', en: '文学' },
  91: { ja: '日本文学', en: '日本文学' },
  92: { ja: '中国文学、その他の東洋文学', en: '中国文学、その他の東洋文学' },
  93: { ja: '英米文学', en: '英米文学' },
  94: { ja: 'ドイツ文学、その他のゲルマン文学', en: 'ドイツ文学、その他のゲルマン文学' },
  95: { ja: 'フランス文学、プロバンス文学', en: 'フランス文学、プロバンス文学' },
  96: { ja: 'スペイン文学、ポルトガル文学', en: 'スペイン文学、ポルトガル文学' },
  97: { ja: 'イタリア文学、その他のロマンス文学', en: 'イタリア文学、その他のロマンス文学' },
  98: { ja: 'ロシア・ソビエト文学、その他のスラブ文学', en: 'ロシア・ソビエト文学、その他のスラブ文学' },
  99: { ja: 'その他の諸言語文学', en: 'その他の諸言語文学' },
  '000': { ja: '総記', en: '総記' },
  '001': { ja: '', en: '' },
  '002': { ja: '知識、学問、学術', en: '知識、学問、学術' },
  '003': { ja: '', en: '' },
  '004': { ja: '', en: '' },
  '005': { ja: '', en: '' },
  '006': { ja: '', en: '' },
  '007': { ja: '情報学、情報科学', en: '情報学、情報科学' },
  '008': { ja: '', en: '' },
  '009': { ja: '', en: '' },
  '010': { ja: '図書館、図書館情報学', en: '図書館、図書館情報学' },
  '011': { ja: '図書館政策、図書館行財政', en: '図書館政策、図書館行財政' },
  '012': { ja: '図書館建築、図書館設備', en: '図書館建築、図書館設備' },
  '013': { ja: '図書館経営・管理', en: '図書館経営・管理' },
  '014': { ja: '情報資源の収集・組織化・保存', en: '情報資源の収集・組織化・保存' },
  '015': { ja: '図書館サービス、図書館活動', en: '図書館サービス、図書館活動' },
  '016': { ja: '各種の図書館', en: '各種の図書館' },
  '017': { ja: '学校図書館', en: '学校図書館' },
  '018': { ja: '専門図書館', en: '専門図書館' },
  '019': { ja: '読書、読書法', en: '読書、読書法' },
  '020': { ja: '図書、書誌学', en: '図書、書誌学' },
  '021': { ja: '著作、編集', en: '著作、編集' },
  '022': { ja: '写本、刊本、造本', en: '写本、刊本、造本' },
  '023': { ja: '出版', en: '出版' },
  '024': { ja: '図書の販売', en: '図書の販売' },
  '025': { ja: '一般書誌、全国書誌', en: '一般書誌、全国書誌' },
  '026': { ja: '稀書目録、善本目録', en: '稀書目録、善本目録' },
  '027': { ja: '特種目録', en: '特種目録' },
  '028': { ja: '選定図書目録、参考図書目録', en: '選定図書目録、参考図書目録' },
  '029': { ja: '蔵書目録、総合目録', en: '蔵書目録、総合目録' },
  '030': { ja: '百科事典', en: '百科事典' },
  '031': { ja: '日本語', en: '日本語' },
  '032': { ja: '中国語', en: '中国語' },
  '033': { ja: '英語', en: '英語' },
  '034': { ja: 'ドイツ語', en: 'ドイツ語' },
  '035': { ja: 'フランス語', en: 'フランス語' },
  '036': { ja: 'スペイン語', en: 'スペイン語' },
  '037': { ja: 'イタリア語', en: 'イタリア語' },
  '038': { ja: 'ロシア語', en: 'ロシア語' },
  '039': { ja: '用語索引', en: '用語索引' },
  '040': { ja: '一般論文集、一般講演集', en: '一般論文集、一般講演集' },
  '041': { ja: '日本語', en: '日本語' },
  '042': { ja: '中国語', en: '中国語' },
  '043': { ja: '英語', en: '英語' },
  '044': { ja: 'ドイツ語', en: 'ドイツ語' },
  '045': { ja: 'フランス語', en: 'フランス語' },
  '046': { ja: 'スペイン語', en: 'スペイン語' },
  '047': { ja: 'イタリア語', en: 'イタリア語' },
  '048': { ja: 'ロシア語', en: 'ロシア語' },
  '049': { ja: '雑著', en: '雑著' },
  '050': { ja: '逐次刊行物', en: '逐次刊行物' },
  '051': { ja: '日本語', en: '日本語' },
  '052': { ja: '中国語', en: '中国語' },
  '053': { ja: '英語', en: '英語' },
  '054': { ja: 'ドイツ語', en: 'ドイツ語' },
  '055': { ja: 'フランス語', en: 'フランス語' },
  '056': { ja: 'スペイン語', en: 'スペイン語' },
  '057': { ja: 'イタリア語', en: 'イタリア語' },
  '058': { ja: 'ロシア語', en: 'ロシア語' },
  '059': { ja: '一般年鑑', en: '一般年鑑' },
  '060': { ja: '団体', en: '団体' },
  '061': { ja: '学術・研究機関', en: '学術・研究機関' },
  '062': { ja: '', en: '' },
  '063': { ja: '文化交流機関', en: '文化交流機関' },
  '064': { ja: '', en: '' },
  '065': { ja: '親睦団体、その他の団体', en: '親睦団体、その他の団体' },
  '066': { ja: '', en: '' },
  '067': { ja: '', en: '' },
  '068': { ja: '', en: '' },
  '069': { ja: '博物館', en: '博物館' },
  '070': { ja: 'ジャーナリズム、新聞', en: 'ジャーナリズム、新聞' },
  '071': { ja: '日本', en: '日本' },
  '072': { ja: 'アジア', en: 'アジア' },
  '073': { ja: 'ヨーロッパ', en: 'ヨーロッパ' },
  '074': { ja: 'アフリカ', en: 'アフリカ' },
  '075': { ja: '北アメリカ', en: '北アメリカ' },
  '076': { ja: '南アメリカ', en: '南アメリカ' },
  '077': { ja: 'オセアニア、両極地方', en: 'オセアニア、両極地方' },
  '078': { ja: '', en: '' },
  '079': { ja: '', en: '' },
  '080': { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  '081': { ja: '日本語', en: '日本語' },
  '082': { ja: '中国語', en: '中国語' },
  '083': { ja: '英語', en: '英語' },
  '084': { ja: 'ドイツ語', en: 'ドイツ語' },
  '085': { ja: 'フランス語', en: 'フランス語' },
  '086': { ja: 'スペイン語', en: 'スペイン語' },
  '087': { ja: 'イタリア語', en: 'イタリア語' },
  '088': { ja: 'ロシア語', en: 'ロシア語' },
  '089': { ja: 'その他の諸言語', en: 'その他の諸言語' },
  '090': { ja: '貴重書、郷土資料、その他の特別コレクション', en: '貴重書、郷土資料、その他の特別コレクション' },
  100: { ja: '哲学', en: '哲学' },
  101: { ja: '哲学理論', en: '哲学理論' },
  102: { ja: '哲学史', en: '哲学史' },
  103: { ja: '参考図書', en: '参考図書' },
  104: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  105: { ja: '逐次刊行物', en: '逐次刊行物' },
  106: { ja: '団体', en: '団体' },
  107: { ja: '研究法、指導法、哲学教育', en: '研究法、指導法、哲学教育' },
  108: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  109: { ja: '', en: '' },
  110: { ja: '哲学各論', en: '哲学各論' },
  111: { ja: '形而上学、存在論', en: '形而上学、存在論' },
  112: { ja: '自然哲学、宇宙論', en: '自然哲学、宇宙論' },
  113: { ja: '人生観、世界観', en: '人生観、世界観' },
  114: { ja: '人間学', en: '人間学' },
  115: { ja: '認識論', en: '認識論' },
  116: { ja: '論理学、弁証法、方法論', en: '論理学、弁証法、方法論' },
  117: { ja: '価値哲学', en: '価値哲学' },
  118: { ja: '文化哲学、技術哲学', en: '文化哲学、技術哲学' },
  119: { ja: '芸術哲学、美学', en: '芸術哲学、美学' },
  120: { ja: '東洋思想', en: '東洋思想' },
  121: { ja: '日本思想', en: '日本思想' },
  122: { ja: '中国思想、中国哲学', en: '中国思想、中国哲学' },
  123: { ja: '経書', en: '経書' },
  124: { ja: '先秦思想、諸子百家', en: '先秦思想、諸子百家' },
  125: { ja: '中世思想、近代思想', en: '中世思想、近代思想' },
  126: { ja: 'インド哲学、バラモン教', en: 'インド哲学、バラモン教' },
  127: { ja: '', en: '' },
  128: { ja: '', en: '' },
  129: { ja: 'その他の東洋思想、アジア哲学', en: 'その他の東洋思想、アジア哲学' },
  130: { ja: '西洋哲学', en: '西洋哲学' },
  131: { ja: '古代哲学', en: '古代哲学' },
  132: { ja: '中世哲学', en: '中世哲学' },
  133: { ja: '近代哲学', en: '近代哲学' },
  134: { ja: 'ドイツ・オーストリア哲学', en: 'ドイツ・オーストリア哲学' },
  135: { ja: 'フランス・オランダ哲学', en: 'フランス・オランダ哲学' },
  136: { ja: 'スペイン・ポルトガル哲学', en: 'スペイン・ポルトガル哲学' },
  137: { ja: 'イタリア哲学', en: 'イタリア哲学' },
  138: { ja: 'ロシア哲学', en: 'ロシア哲学' },
  139: { ja: 'その他の哲学', en: 'その他の哲学' },
  140: { ja: '心理学', en: '心理学' },
  141: { ja: '普通心理学、心理各論', en: '普通心理学、心理各論' },
  142: { ja: '', en: '' },
  143: { ja: '発達心理学', en: '発達心理学' },
  144: { ja: '', en: '' },
  145: { ja: '異常心理学', en: '異常心理学' },
  146: { ja: '臨床心理学、精神分析学', en: '臨床心理学、精神分析学' },
  147: { ja: '超心理学、心霊研究', en: '超心理学、心霊研究' },
  148: { ja: '相法、易占', en: '相法、易占' },
  150: { ja: '倫理学、道徳', en: '倫理学、道徳' },
  151: { ja: '倫理各論', en: '倫理各論' },
  152: { ja: '家庭倫理、性倫理', en: '家庭倫理、性倫理' },
  153: { ja: '職業倫理', en: '職業倫理' },
  154: { ja: '社会倫理（社会道徳）', en: '社会倫理（社会道徳）' },
  155: { ja: '国体論、詔勅', en: '国体論、詔勅' },
  156: { ja: '武士道', en: '武士道' },
  157: { ja: '報徳教、石門心学', en: '報徳教、石門心学' },
  158: { ja: 'その他の特定主題', en: 'その他の特定主題' },
  159: { ja: '人生訓、教訓', en: '人生訓、教訓' },
  160: { ja: '宗教', en: '宗教' },
  161: { ja: '宗教学、宗教思想', en: '宗教学、宗教思想' },
  162: { ja: '宗教史・事情', en: '宗教史・事情' },
  163: { ja: '原始宗教、宗教民族学', en: '原始宗教、宗教民族学' },
  164: { ja: '神話、神話学', en: '神話、神話学' },
  165: { ja: '比較宗教', en: '比較宗教' },
  166: { ja: '道教', en: '道教' },
  167: { ja: 'イスラム', en: 'イスラム' },
  168: { ja: 'ヒンズー教、ジャイナ教', en: 'ヒンズー教、ジャイナ教' },
  169: { ja: 'その他の宗教、新興宗教', en: 'その他の宗教、新興宗教' },
  170: { ja: '神道', en: '神道' },
  171: { ja: '神道思想、神道説', en: '神道思想、神道説' },
  172: { ja: '神祇・神道史', en: '神祇・神道史' },
  173: { ja: '神典', en: '神典' },
  174: { ja: '信仰録、説教集', en: '信仰録、説教集' },
  175: { ja: '神社、神職', en: '神社、神職' },
  176: { ja: '祭祀', en: '祭祀' },
  177: { ja: '布教、伝道', en: '布教、伝道' },
  178: { ja: '各教派、教派神道', en: '各教派、教派神道' },
  179: { ja: '', en: '' },
  180: { ja: '仏教', en: '仏教' },
  181: { ja: '仏教教理、仏教哲学', en: '仏教教理、仏教哲学' },
  182: { ja: '仏教史', en: '仏教史' },
  183: { ja: '経典', en: '経典' },
  184: { ja: '法話・説教集', en: '法話・説教集' },
  185: { ja: '寺院、僧職', en: '寺院、僧職' },
  186: { ja: '仏会', en: '仏会' },
  187: { ja: '布教、伝道', en: '布教、伝道' },
  188: { ja: '各宗', en: '各宗' },
  189: { ja: '', en: '' },
  190: { ja: 'キリスト教', en: 'キリスト教' },
  191: { ja: '教義、キリスト教神学', en: '教義、キリスト教神学' },
  192: { ja: 'キリスト教史、迫害史', en: 'キリスト教史、迫害史' },
  193: { ja: '聖書', en: '聖書' },
  194: { ja: '信仰録、説教集', en: '信仰録、説教集' },
  195: { ja: '教会、聖職', en: '教会、聖職' },
  196: { ja: '典礼、祭式、礼拝', en: '典礼、祭式、礼拝' },
  197: { ja: '布教、伝道', en: '布教、伝道' },
  198: { ja: '各教派、教会史', en: '各教派、教会史' },
  199: { ja: 'ユダヤ教', en: 'ユダヤ教' },
  200: { ja: '歴史', en: '歴史' },
  201: { ja: '歴史学', en: '歴史学' },
  202: { ja: '歴史補助学', en: '歴史補助学' },
  203: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  204: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  205: { ja: '逐次刊行物', en: '逐次刊行物' },
  206: { ja: '団体', en: '団体' },
  207: { ja: '研究法、指導法、歴史教育', en: '研究法、指導法、歴史教育' },
  208: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  209: { ja: '世界史、文化史', en: '世界史、文化史' },
  210: { ja: '日本史', en: '日本史' },
  211: { ja: '北海道地方', en: '北海道地方' },
  212: { ja: '東北地方', en: '東北地方' },
  213: { ja: '関東地方', en: '関東地方' },
  214: { ja: '北陸地方', en: '北陸地方' },
  215: { ja: '中部地方', en: '中部地方' },
  216: { ja: '近畿地方', en: '近畿地方' },
  217: { ja: '中国地方', en: '中国地方' },
  218: { ja: '四国地方', en: '四国地方' },
  219: { ja: '九州地方', en: '九州地方' },
  220: { ja: 'アジア史、東洋史', en: 'アジア史、東洋史' },
  221: { ja: '朝鮮', en: '朝鮮' },
  222: { ja: '中国', en: '中国' },
  223: { ja: '東南アジア', en: '東南アジア' },
  224: { ja: 'インドネシア', en: 'インドネシア' },
  225: { ja: 'インド', en: 'インド' },
  226: { ja: '西南アジア、中東（中近東） → ', en: '西南アジア、中東（中近東） → ' },
  227: { ja: '西南アジア、中東（中近東）', en: '西南アジア、中東（中近東）' },
  228: { ja: 'アラブ諸国 → ', en: 'アラブ諸国 → ' },
  229: { ja: 'アジアロシア', en: 'アジアロシア' },
  230: { ja: 'ヨーロッパ史、西洋史', en: 'ヨーロッパ史、西洋史' },
  231: { ja: '古代ギリシア', en: '古代ギリシア' },
  232: { ja: '古代ローマ', en: '古代ローマ' },
  233: { ja: 'イギリス、英国', en: 'イギリス、英国' },
  234: { ja: 'ドイツ、中欧', en: 'ドイツ、中欧' },
  235: { ja: 'フランス', en: 'フランス' },
  236: { ja: 'スペイン（イスパニア）', en: 'スペイン（イスパニア）' },
  237: { ja: 'イタリア', en: 'イタリア' },
  238: { ja: 'ロシア', en: 'ロシア' },
  239: { ja: 'バルカン諸国', en: 'バルカン諸国' },
  240: { ja: 'アフリカ史', en: 'アフリカ史' },
  241: { ja: '北アフリカ', en: '北アフリカ' },
  242: { ja: 'エジプト', en: 'エジプト' },
  243: { ja: 'マグレブ諸国', en: 'マグレブ諸国' },
  244: { ja: '西アフリカ', en: '西アフリカ' },
  245: { ja: '東アフリカ', en: '東アフリカ' },
  246: { ja: '', en: '' },
  247: { ja: '', en: '' },
  248: { ja: '南アフリカ', en: '南アフリカ' },
  249: { ja: 'インド洋のアフリカ諸島', en: 'インド洋のアフリカ諸島' },
  250: { ja: '北アメリカ史', en: '北アメリカ史' },
  251: { ja: 'カナダ', en: 'カナダ' },
  252: { ja: '', en: '' },
  253: { ja: 'アメリカ合衆国', en: 'アメリカ合衆国' },
  255: { ja: 'ラテンアメリカ（中南米）', en: 'ラテンアメリカ（中南米）' },
  256: { ja: 'メキシコ', en: 'メキシコ' },
  257: { ja: '中央アメリカ（中米諸国）', en: '中央アメリカ（中米諸国）' },
  258: { ja: '', en: '' },
  259: { ja: '西インド諸島', en: '西インド諸島' },
  260: { ja: '南アメリカ史', en: '南アメリカ史' },
  261: { ja: '北部諸国（カリブ沿海諸国）', en: '北部諸国（カリブ沿海諸国）' },
  262: { ja: 'ブラジル', en: 'ブラジル' },
  263: { ja: 'パラグアイ', en: 'パラグアイ' },
  264: { ja: 'ウルグアイ', en: 'ウルグアイ' },
  265: { ja: 'アルゼンチン', en: 'アルゼンチン' },
  266: { ja: 'チリ', en: 'チリ' },
  267: { ja: 'ボリビア', en: 'ボリビア' },
  268: { ja: 'ペルー', en: 'ペルー' },
  269: { ja: '', en: '' },
  270: { ja: 'オセアニア史、両極地方史', en: 'オセアニア史、両極地方史' },
  271: { ja: 'オーストラリア', en: 'オーストラリア' },
  272: { ja: 'ニュージーランド', en: 'ニュージーランド' },
  273: { ja: 'メラネシア', en: 'メラネシア' },
  274: { ja: 'ミクロネシア', en: 'ミクロネシア' },
  275: { ja: 'ポリネシア', en: 'ポリネシア' },
  276: { ja: 'ハワイ', en: 'ハワイ' },
  277: { ja: '両極地方', en: '両極地方' },
  278: { ja: '北極、北極地方', en: '北極、北極地方' },
  279: { ja: '南極、南極地方', en: '南極、南極地方' },
  280: { ja: '伝記', en: '伝記' },
  281: { ja: '日本', en: '日本' },
  282: { ja: 'アジア', en: 'アジア' },
  283: { ja: 'ヨーロッパ', en: 'ヨーロッパ' },
  284: { ja: 'アフリカ', en: 'アフリカ' },
  285: { ja: '北アメリカ', en: '北アメリカ' },
  286: { ja: '南アメリカ', en: '南アメリカ' },
  287: { ja: 'オセアニア、両極地方', en: 'オセアニア、両極地方' },
  288: { ja: '系譜、家史、皇室', en: '系譜、家史、皇室' },
  289: { ja: '個人伝記', en: '個人伝記' },
  290: { ja: '地理、地誌、紀行', en: '地理、地誌、紀行' },
  291: { ja: '日本', en: '日本' },
  292: { ja: 'アジア', en: 'アジア' },
  293: { ja: 'ヨーロッパ', en: 'ヨーロッパ' },
  294: { ja: 'アフリカ', en: 'アフリカ' },
  295: { ja: '北アメリカ', en: '北アメリカ' },
  296: { ja: '南アメリカ', en: '南アメリカ' },
  297: { ja: 'オセアニア、両極地方', en: 'オセアニア、両極地方' },
  298: { ja: '', en: '' },
  299: { ja: '海洋', en: '海洋' },
  300: { ja: '社会科学', en: '社会科学' },
  301: { ja: '理論、方法論', en: '理論、方法論' },
  302: { ja: '政治・経済・社会・文化事情', en: '政治・経済・社会・文化事情' },
  303: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  304: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  305: { ja: '逐次刊行物', en: '逐次刊行物' },
  306: { ja: '団体', en: '団体' },
  307: { ja: '研究法、指導法、社会科学教育', en: '研究法、指導法、社会科学教育' },
  308: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  309: { ja: '社会思想', en: '社会思想' },
  310: { ja: '政治', en: '政治' },
  311: { ja: '政治学、政治思想', en: '政治学、政治思想' },
  312: { ja: '政治史・事情', en: '政治史・事情' },
  313: { ja: '国家の形態、政治体制', en: '国家の形態、政治体制' },
  314: { ja: '議会', en: '議会' },
  315: { ja: '政党、政治結社', en: '政党、政治結社' },
  316: { ja: '国家と個人・宗教・民族', en: '国家と個人・宗教・民族' },
  317: { ja: '行政', en: '行政' },
  318: { ja: '地方自治、地方行政', en: '地方自治、地方行政' },
  319: { ja: '外交、国際問題', en: '外交、国際問題' },
  320: { ja: '法律', en: '法律' },
  321: { ja: '法学', en: '法学' },
  322: { ja: '法制史', en: '法制史' },
  323: { ja: '憲法', en: '憲法' },
  324: { ja: '民法、民事法', en: '民法、民事法' },
  325: { ja: '商法、商事法', en: '商法、商事法' },
  326: { ja: '刑法、刑事法', en: '刑法、刑事法' },
  327: { ja: '司法、訴訟手続法', en: '司法、訴訟手続法' },
  328: { ja: '諸法', en: '諸法' },
  329: { ja: '国際法', en: '国際法' },
  330: { ja: '経済', en: '経済' },
  331: { ja: '経済学、経済思想', en: '経済学、経済思想' },
  332: { ja: '経済史・事情、経済体制', en: '経済史・事情、経済体制' },
  333: { ja: '経済政策、国際経済', en: '経済政策、国際経済' },
  334: { ja: '人口、土地、資源', en: '人口、土地、資源' },
  335: { ja: '企業、経営', en: '企業、経営' },
  336: { ja: '経営管理', en: '経営管理' },
  337: { ja: '貨幣、通貨', en: '貨幣、通貨' },
  338: { ja: '金融、銀行、信託', en: '金融、銀行、信託' },
  339: { ja: '保険', en: '保険' },
  340: { ja: '財政', en: '財政' },
  341: { ja: '財政学、財政思想', en: '財政学、財政思想' },
  342: { ja: '財政史・事情', en: '財政史・事情' },
  343: { ja: '財政政策、財務行政', en: '財政政策、財務行政' },
  344: { ja: '予算、決算', en: '予算、決算' },
  345: { ja: '租税', en: '租税' },
  346: { ja: '', en: '' },
  347: { ja: '公債、国債', en: '公債、国債' },
  348: { ja: '専売、国有財産', en: '専売、国有財産' },
  349: { ja: '地方財政', en: '地方財政' },
  350: { ja: '統計', en: '統計' },
  351: { ja: '日本', en: '日本' },
  352: { ja: 'アジア', en: 'アジア' },
  353: { ja: 'ヨーロッパ', en: 'ヨーロッパ' },
  354: { ja: 'アフリカ', en: 'アフリカ' },
  355: { ja: '北アメリカ', en: '北アメリカ' },
  356: { ja: '南アメリカ', en: '南アメリカ' },
  357: { ja: 'オセアニア、両極地方', en: 'オセアニア、両極地方' },
  358: { ja: '人口統計、国勢調査', en: '人口統計、国勢調査' },
  359: { ja: '各種の統計書', en: '各種の統計書' },
  360: { ja: '社会', en: '社会' },
  361: { ja: '社会学', en: '社会学' },
  362: { ja: '社会史、社会体制', en: '社会史、社会体制' },
  363: { ja: '', en: '' },
  364: { ja: '社会保障', en: '社会保障' },
  365: { ja: '生活・消費者問題', en: '生活・消費者問題' },
  366: { ja: '労働経済、労働問題', en: '労働経済、労働問題' },
  367: { ja: '家族問題、男性・女性問題、老人問題', en: '家族問題、男性・女性問題、老人問題' },
  368: { ja: '社会病理', en: '社会病理' },
  369: { ja: '社会福祉', en: '社会福祉' },
  370: { ja: '教育', en: '教育' },
  371: { ja: '教育学、教育思想', en: '教育学、教育思想' },
  372: { ja: '教育史・事情', en: '教育史・事情' },
  373: { ja: '教育政策、教育制度、教育行財政', en: '教育政策、教育制度、教育行財政' },
  374: { ja: '学校経営・管理、学校保健', en: '学校経営・管理、学校保健' },
  375: { ja: '教育課程、学習指導、教科別教育', en: '教育課程、学習指導、教科別教育' },
  376: { ja: '幼児・初等・中等教育', en: '幼児・初等・中等教育' },
  377: { ja: '大学、高等・専門教育、学術行政', en: '大学、高等・専門教育、学術行政' },
  378: { ja: '障害児教育（特別支援教育）', en: '障害児教育（特別支援教育）' },
  379: { ja: '社会教育', en: '社会教育' },
  380: { ja: '風俗習慣、民俗学、民族学', en: '風俗習慣、民俗学、民族学' },
  381: { ja: '', en: '' },
  382: { ja: '風俗史、民俗誌、民族誌', en: '風俗史、民俗誌、民族誌' },
  383: { ja: '衣食住の習俗', en: '衣食住の習俗' },
  384: { ja: '社会・家庭生活の習俗', en: '社会・家庭生活の習俗' },
  385: { ja: '通過儀礼、冠婚葬祭', en: '通過儀礼、冠婚葬祭' },
  386: { ja: '年中行事、祭礼', en: '年中行事、祭礼' },
  387: { ja: '民間信仰、迷信（俗信）', en: '民間信仰、迷信（俗信）' },
  388: { ja: '伝説、民話（昔話）', en: '伝説、民話（昔話）' },
  389: { ja: '民族学、文化人類学', en: '民族学、文化人類学' },
  390: { ja: '国防、軍事', en: '国防、軍事' },
  391: { ja: '戦争、戦略、戦術', en: '戦争、戦略、戦術' },
  392: { ja: '国防史・事情、軍事史・事情', en: '国防史・事情、軍事史・事情' },
  393: { ja: '国防政策・行政・法令', en: '国防政策・行政・法令' },
  394: { ja: '軍事医学、兵食', en: '軍事医学、兵食' },
  395: { ja: '軍事施設、軍需品', en: '軍事施設、軍需品' },
  396: { ja: '陸軍', en: '陸軍' },
  397: { ja: '海軍', en: '海軍' },
  398: { ja: '空軍', en: '空軍' },
  399: { ja: '古代兵法、軍学', en: '古代兵法、軍学' },
  400: { ja: '自然科学', en: '自然科学' },
  401: { ja: '科学理論、科学哲学', en: '科学理論、科学哲学' },
  402: { ja: '科学史・事情', en: '科学史・事情' },
  403: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  404: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  405: { ja: '逐次刊行物', en: '逐次刊行物' },
  406: { ja: '団体', en: '団体' },
  407: { ja: '研究法、指導法、科学教育', en: '研究法、指導法、科学教育' },
  408: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  409: { ja: '科学技術政策、科学技術行政', en: '科学技術政策、科学技術行政' },
  410: { ja: '数学', en: '数学' },
  411: { ja: '代数学', en: '代数学' },
  412: { ja: '数論（整数論）', en: '数論（整数論）' },
  413: { ja: '解析学', en: '解析学' },
  414: { ja: '幾何学', en: '幾何学' },
  415: { ja: '位相数学', en: '位相数学' },
  416: { ja: '', en: '' },
  417: { ja: '確率論、数理統計学', en: '確率論、数理統計学' },
  418: { ja: '計算法', en: '計算法' },
  419: { ja: '和算、中国算法', en: '和算、中国算法' },
  420: { ja: '物理学', en: '物理学' },
  421: { ja: '理論物理学', en: '理論物理学' },
  422: { ja: '', en: '' },
  423: { ja: '力学', en: '力学' },
  424: { ja: '振動学、音響学', en: '振動学、音響学' },
  425: { ja: '光学', en: '光学' },
  426: { ja: '熱学', en: '熱学' },
  427: { ja: '電磁気学', en: '電磁気学' },
  428: { ja: '物性物理学', en: '物性物理学' },
  429: { ja: '原子物理学', en: '原子物理学' },
  430: { ja: '化学', en: '化学' },
  431: { ja: '物理化学、理論化学', en: '物理化学、理論化学' },
  432: { ja: '実験化学（化学実験法）', en: '実験化学（化学実験法）' },
  433: { ja: '分析化学（化学分析）', en: '分析化学（化学分析）' },
  434: { ja: '合成化学（化学合成）', en: '合成化学（化学合成）' },
  435: { ja: '無機化学', en: '無機化学' },
  436: { ja: '金属元素とその化合物', en: '金属元素とその化合物' },
  437: { ja: '有機化学', en: '有機化学' },
  438: { ja: '環式化合物の化学', en: '環式化合物の化学' },
  439: { ja: '天然物質の化学', en: '天然物質の化学' },
  440: { ja: '天文学、宇宙科学', en: '天文学、宇宙科学' },
  441: { ja: '理論天文学、数理天文学', en: '理論天文学、数理天文学' },
  442: { ja: '実地天文学、天体観測法', en: '実地天文学、天体観測法' },
  443: { ja: '恒星、恒星天文学', en: '恒星、恒星天文学' },
  444: { ja: '太陽、太陽物理学', en: '太陽、太陽物理学' },
  445: { ja: '惑星、衛星', en: '惑星、衛星' },
  446: { ja: '月', en: '月' },
  447: { ja: '彗星、流星', en: '彗星、流星' },
  448: { ja: '地球、天文地理学', en: '地球、天文地理学' },
  449: { ja: '時法、暦学', en: '時法、暦学' },
  450: { ja: '地球科学、地学', en: '地球科学、地学' },
  451: { ja: '気象学', en: '気象学' },
  452: { ja: '海洋学', en: '海洋学' },
  453: { ja: '地震学', en: '地震学' },
  454: { ja: '地形学', en: '地形学' },
  455: { ja: '地質学', en: '地質学' },
  456: { ja: '地史学、層位学', en: '地史学、層位学' },
  457: { ja: '古生物学、化石', en: '古生物学、化石' },
  458: { ja: '岩石学', en: '岩石学' },
  459: { ja: '鉱物学', en: '鉱物学' },
  460: { ja: '生物科学、一般生物学', en: '生物科学、一般生物学' },
  461: { ja: '理論生物学、生命論', en: '理論生物学、生命論' },
  462: { ja: '生物地理、生物誌', en: '生物地理、生物誌' },
  463: { ja: '細胞学', en: '細胞学' },
  464: { ja: '生化学', en: '生化学' },
  465: { ja: '微生物学', en: '微生物学' },
  466: { ja: '', en: '' },
  467: { ja: '遺伝学', en: '遺伝学' },
  468: { ja: '生態学', en: '生態学' },
  469: { ja: '人類学', en: '人類学' },
  470: { ja: '植物学', en: '植物学' },
  471: { ja: '一般植物学', en: '一般植物学' },
  472: { ja: '植物地理、植物誌', en: '植物地理、植物誌' },
  473: { ja: '葉状植物', en: '葉状植物' },
  474: { ja: '藻類、菌類', en: '藻類、菌類' },
  475: { ja: 'コケ植物（蘚苔類）', en: 'コケ植物（蘚苔類）' },
  476: { ja: 'シダ植物', en: 'シダ植物' },
  477: { ja: '種子植物', en: '種子植物' },
  478: { ja: '裸子植物', en: '裸子植物' },
  479: { ja: '被子植物', en: '被子植物' },
  480: { ja: '動物学', en: '動物学' },
  481: { ja: '一般動物学', en: '一般動物学' },
  482: { ja: '動物地理、動物誌', en: '動物地理、動物誌' },
  483: { ja: '無脊椎動物', en: '無脊椎動物' },
  484: { ja: '軟体動物、貝類学', en: '軟体動物、貝類学' },
  485: { ja: '節足動物', en: '節足動物' },
  486: { ja: '昆虫類', en: '昆虫類' },
  487: { ja: '脊椎動物', en: '脊椎動物' },
  488: { ja: '鳥類', en: '鳥類' },
  489: { ja: '哺乳類', en: '哺乳類' },
  490: { ja: '医学', en: '医学' },
  491: { ja: '基礎医学', en: '基礎医学' },
  492: { ja: '臨床医学、診断・治療', en: '臨床医学、診断・治療' },
  493: { ja: '内科学', en: '内科学' },
  494: { ja: '外科学', en: '外科学' },
  495: { ja: '婦人科学、産科学', en: '婦人科学、産科学' },
  496: { ja: '眼科学、耳鼻咽喉科学', en: '眼科学、耳鼻咽喉科学' },
  497: { ja: '歯科学', en: '歯科学' },
  498: { ja: '衛生学、公衆衛生、予防医学', en: '衛生学、公衆衛生、予防医学' },
  499: { ja: '薬学', en: '薬学' },
  500: { ja: '技術、工学', en: '技術、工学' },
  501: { ja: '工業基礎学', en: '工業基礎学' },
  502: { ja: '技術史、工学史', en: '技術史、工学史' },
  503: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  504: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  505: { ja: '逐次刊行物', en: '逐次刊行物' },
  506: { ja: '団体', en: '団体' },
  507: { ja: '研究法、指導法、技術教育', en: '研究法、指導法、技術教育' },
  508: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  509: { ja: '工業、工業経済', en: '工業、工業経済' },
  510: { ja: '建設工学、土木工学', en: '建設工学、土木工学' },
  511: { ja: '土木力学、建設材料', en: '土木力学、建設材料' },
  512: { ja: '測量', en: '測量' },
  513: { ja: '土木設計・施工法', en: '土木設計・施工法' },
  514: { ja: '道路工学', en: '道路工学' },
  515: { ja: '橋梁工学', en: '橋梁工学' },
  516: { ja: '鉄道工学', en: '鉄道工学' },
  517: { ja: '河海工学、河川工学', en: '河海工学、河川工学' },
  518: { ja: '衛生工学、都市工学 ', en: '衛生工学、都市工学 ' },
  519: { ja: '環境工学、公害', en: '環境工学、公害' },
  520: { ja: '建築学', en: '建築学' },
  521: { ja: '日本の建築', en: '日本の建築' },
  522: { ja: '東洋の建築、アジアの建築', en: '東洋の建築、アジアの建築' },
  523: { ja: '西洋の建築、その他の様式の建築', en: '西洋の建築、その他の様式の建築' },
  524: { ja: '建築構造', en: '建築構造' },
  525: { ja: '建築計画・施工', en: '建築計画・施工' },
  526: { ja: '各種の建築', en: '各種の建築' },
  527: { ja: '住宅建築', en: '住宅建築' },
  528: { ja: '建築設備、設備工学', en: '建築設備、設備工学' },
  529: { ja: '建築意匠・装飾', en: '建築意匠・装飾' },
  530: { ja: '機械工学', en: '機械工学' },
  531: { ja: '機械力学・材料・設計', en: '機械力学・材料・設計' },
  532: { ja: '機械工作、工作機械', en: '機械工作、工作機械' },
  533: { ja: '熱機関、熱工学', en: '熱機関、熱工学' },
  534: { ja: '流体機械、流体工学', en: '流体機械、流体工学' },
  535: { ja: '精密機器、光学機器', en: '精密機器、光学機器' },
  536: { ja: '運輸工学、車両、運搬機械', en: '運輸工学、車両、運搬機械' },
  537: { ja: '自動車工学', en: '自動車工学' },
  538: { ja: '航空工学、宇宙工学', en: '航空工学、宇宙工学' },
  539: { ja: '原子力工学', en: '原子力工学' },
  540: { ja: '電気工学', en: '電気工学' },
  541: { ja: '電気回路・計測・材料', en: '電気回路・計測・材料' },
  542: { ja: '電気機器', en: '電気機器' },
  543: { ja: '発電', en: '発電' },
  544: { ja: '送電、変電、配電', en: '送電、変電、配電' },
  545: { ja: '電灯、照明、電熱', en: '電灯、照明、電熱' },
  547: { ja: '通信工学、電気通信', en: '通信工学、電気通信' },
  548: { ja: '情報工学', en: '情報工学' },
  549: { ja: '電子工学', en: '電子工学' },
  550: { ja: '海洋工学、船舶工学', en: '海洋工学、船舶工学' },
  551: { ja: '理論造船学', en: '理論造船学' },
  552: { ja: '船体構造・材料・施工', en: '船体構造・材料・施工' },
  553: { ja: '船体艤装、船舶設備', en: '船体艤装、船舶設備' },
  554: { ja: '舶用機関（造機）', en: '舶用機関（造機）' },
  555: { ja: '船舶修理、保守', en: '船舶修理、保守' },
  556: { ja: '各種の船舶・艦艇', en: '各種の船舶・艦艇' },
  557: { ja: '航海、航海学', en: '航海、航海学' },
  558: { ja: '海洋開発', en: '海洋開発' },
  559: { ja: '兵器、軍事工学', en: '兵器、軍事工学' },
  560: { ja: '金属工学、鉱山工学', en: '金属工学、鉱山工学' },
  561: { ja: '採鉱、選鉱', en: '採鉱、選鉱' },
  562: { ja: '各種の金属鉱床・採掘', en: '各種の金属鉱床・採掘' },
  563: { ja: '冶金、合金', en: '冶金、合金' },
  564: { ja: '鉄鋼', en: '鉄鋼' },
  565: { ja: '非鉄金属', en: '非鉄金属' },
  566: { ja: '金属加工、製造冶金', en: '金属加工、製造冶金' },
  567: { ja: '石炭', en: '石炭' },
  568: { ja: '石油', en: '石油' },
  569: { ja: '非金属鉱物、土石採取業', en: '非金属鉱物、土石採取業' },
  570: { ja: '化学工業', en: '化学工業' },
  571: { ja: '化学工学、化学機器', en: '化学工学、化学機器' },
  572: { ja: '電気化学工業', en: '電気化学工業' },
  573: { ja: 'セラミックス、窯業、珪酸塩化学工業', en: 'セラミックス、窯業、珪酸塩化学工業' },
  574: { ja: '化学薬品', en: '化学薬品' },
  575: { ja: '燃料、爆発物', en: '燃料、爆発物' },
  576: { ja: '油脂類', en: '油脂類' },
  577: { ja: '染料', en: '染料' },
  578: { ja: '高分子化学工業', en: '高分子化学工業' },
  579: { ja: 'その他の化学工業', en: 'その他の化学工業' },
  580: { ja: '製造工業', en: '製造工業' },
  581: { ja: '金属製品', en: '金属製品' },
  582: { ja: '事務機器、家庭機器、楽器', en: '事務機器、家庭機器、楽器' },
  583: { ja: '木工業、木製品', en: '木工業、木製品' },
  584: { ja: '皮革工業、皮革製品', en: '皮革工業、皮革製品' },
  585: { ja: 'パルプ・製紙工業', en: 'パルプ・製紙工業' },
  586: { ja: '繊維工学', en: '繊維工学' },
  587: { ja: '染色加工、染色業', en: '染色加工、染色業' },
  588: { ja: '食品工業', en: '食品工業' },
  589: { ja: 'その他の雑工業', en: 'その他の雑工業' },
  590: { ja: '家政学、生活科学', en: '家政学、生活科学' },
  591: { ja: '家庭経済・経営', en: '家庭経済・経営' },
  592: { ja: '家庭理工学', en: '家庭理工学' },
  593: { ja: '衣服、裁縫', en: '衣服、裁縫' },
  594: { ja: '手芸', en: '手芸' },
  595: { ja: '理容、美容', en: '理容、美容' },
  596: { ja: '食品、料理', en: '食品、料理' },
  597: { ja: '住居、家具調度', en: '住居、家具調度' },
  598: { ja: '家庭衛生', en: '家庭衛生' },
  599: { ja: '育児', en: '育児' },
  600: { ja: '産業', en: '産業' },
  601: { ja: '産業政策・行政、総合開発', en: '産業政策・行政、総合開発' },
  602: { ja: '産業史・事情、物産誌', en: '産業史・事情、物産誌' },
  603: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  604: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  605: { ja: '逐次刊行物', en: '逐次刊行物' },
  606: { ja: '団体', en: '団体' },
  607: { ja: '研究法、指導法、産業教育', en: '研究法、指導法、産業教育' },
  608: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  609: { ja: '度量衡、計量法', en: '度量衡、計量法' },
  610: { ja: '農業', en: '農業' },
  611: { ja: '農業経済・行政・経営', en: '農業経済・行政・経営' },
  612: { ja: '農業史・事情', en: '農業史・事情' },
  613: { ja: '農業基礎学', en: '農業基礎学' },
  614: { ja: '農業工学', en: '農業工学' },
  615: { ja: '作物栽培、作物学', en: '作物栽培、作物学' },
  616: { ja: '食用作物', en: '食用作物' },
  617: { ja: '工芸作物', en: '工芸作物' },
  618: { ja: '繊維作物', en: '繊維作物' },
  619: { ja: '農産物製造・加工', en: '農産物製造・加工' },
  620: { ja: '園芸', en: '園芸' },
  621: { ja: '園芸経済・行政・経営', en: '園芸経済・行政・経営' },
  622: { ja: '園芸史・事情', en: '園芸史・事情' },
  623: { ja: '園芸植物学、病虫害', en: '園芸植物学、病虫害' },
  624: { ja: '温室、温床、園芸用具', en: '温室、温床、園芸用具' },
  625: { ja: '果樹園芸', en: '果樹園芸' },
  626: { ja: '蔬菜園芸', en: '蔬菜園芸' },
  627: { ja: '花卉園芸（草花）', en: '花卉園芸（草花）' },
  628: { ja: '園芸利用', en: '園芸利用' },
  629: { ja: '造園', en: '造園' },
  630: { ja: '蚕糸業', en: '蚕糸業' },
  631: { ja: '蚕糸経済・行政・経営', en: '蚕糸経済・行政・経営' },
  632: { ja: '蚕糸業史・事情', en: '蚕糸業史・事情' },
  633: { ja: '蚕学、蚕業基礎学', en: '蚕学、蚕業基礎学' },
  634: { ja: '蚕種', en: '蚕種' },
  635: { ja: '飼育法', en: '飼育法' },
  636: { ja: 'くわ、栽桑', en: 'くわ、栽桑' },
  637: { ja: '蚕室、蚕具', en: '蚕室、蚕具' },
  638: { ja: 'まゆ', en: 'まゆ' },
  639: { ja: '製糸、生糸、蚕糸利用', en: '製糸、生糸、蚕糸利用' },
  640: { ja: '畜産業', en: '畜産業' },
  641: { ja: '畜産経済・行政・経営', en: '畜産経済・行政・経営' },
  642: { ja: '畜産史・事情', en: '畜産史・事情' },
  643: { ja: '家畜の繁殖、家畜飼料', en: '家畜の繁殖、家畜飼料' },
  644: { ja: '家畜の管理、畜舎、用具', en: '家畜の管理、畜舎、用具' },
  645: { ja: '家畜、畜産動物、愛玩動物', en: '家畜、畜産動物、愛玩動物' },
  646: { ja: '家禽', en: '家禽' },
  648: { ja: '畜産製造、畜産物', en: '畜産製造、畜産物' },
  649: { ja: '獣医学', en: '獣医学' },
  650: { ja: '林業', en: '林業' },
  651: { ja: '林業経済・行政・経営', en: '林業経済・行政・経営' },
  652: { ja: '森林史、林業史・事情', en: '森林史、林業史・事情' },
  653: { ja: '森林立地、造林', en: '森林立地、造林' },
  654: { ja: '森林保護', en: '森林保護' },
  655: { ja: '森林施業', en: '森林施業' },
  656: { ja: '森林工学', en: '森林工学' },
  657: { ja: '森林利用、林産物、木材学', en: '森林利用、林産物、木材学' },
  658: { ja: '林産製造', en: '林産製造' },
  659: { ja: '狩猟', en: '狩猟' },
  660: { ja: '水産業', en: '水産業' },
  661: { ja: '水産経済・行政・経営', en: '水産経済・行政・経営' },
  662: { ja: '水産業および漁業史・事情', en: '水産業および漁業史・事情' },
  663: { ja: '水産基礎学', en: '水産基礎学' },
  664: { ja: '漁労、漁業各論', en: '漁労、漁業各論' },
  665: { ja: '漁船、漁具', en: '漁船、漁具' },
  666: { ja: '水産増殖、養殖業', en: '水産増殖、養殖業' },
  667: { ja: '水産製造、水産食品', en: '水産製造、水産食品' },
  668: { ja: '水産物利用、水産利用工業', en: '水産物利用、水産利用工業' },
  669: { ja: '製塩、塩業', en: '製塩、塩業' },
  670: { ja: '商業', en: '商業' },
  671: { ja: '商業政策・行政', en: '商業政策・行政' },
  672: { ja: '商業史・事情', en: '商業史・事情' },
  673: { ja: '商業経営、商店', en: '商業経営、商店' },
  674: { ja: '広告、宣伝', en: '広告、宣伝' },
  675: { ja: 'マーケティング', en: 'マーケティング' },
  676: { ja: '取引所', en: '取引所' },
  678: { ja: '貿易', en: '貿易' },
  679: { ja: '', en: '' },
  680: { ja: '運輸、交通', en: '運輸、交通' },
  681: { ja: '交通政策・行政・経営', en: '交通政策・行政・経営' },
  682: { ja: '交通史・事情', en: '交通史・事情' },
  683: { ja: '海運', en: '海運' },
  684: { ja: '内陸水運、運河交通', en: '内陸水運、運河交通' },
  685: { ja: '陸運、道路運輸', en: '陸運、道路運輸' },
  686: { ja: '鉄道運輸', en: '鉄道運輸' },
  687: { ja: '航空運輸', en: '航空運輸' },
  688: { ja: '倉庫業', en: '倉庫業' },
  689: { ja: '観光事業', en: '観光事業' },
  690: { ja: '通信事業', en: '通信事業' },
  691: { ja: '通信政策・行政・法令', en: '通信政策・行政・法令' },
  692: { ja: '通信事業史・事情', en: '通信事業史・事情' },
  693: { ja: '郵便、郵政事業', en: '郵便、郵政事業' },
  694: { ja: '電気通信事業', en: '電気通信事業' },
  695: { ja: '', en: '' },
  696: { ja: '', en: '' },
  697: { ja: '', en: '' },
  698: { ja: '', en: '' },
  699: { ja: '放送事業', en: '放送事業' },
  700: { ja: '芸術、美術', en: '芸術、美術' },
  701: { ja: '芸術理論、美学', en: '芸術理論、美学' },
  702: { ja: '芸術史、美術史', en: '芸術史、美術史' },
  703: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  704: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  705: { ja: '逐次刊行物', en: '逐次刊行物' },
  706: { ja: '団体', en: '団体' },
  707: { ja: '研究法、指導法、芸術教育', en: '研究法、指導法、芸術教育' },
  708: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  709: { ja: '芸術政策、文化財', en: '芸術政策、文化財' },
  710: { ja: '彫刻', en: '彫刻' },
  711: { ja: '彫塑材料・技法', en: '彫塑材料・技法' },
  712: { ja: '彫刻史、各国の彫刻', en: '彫刻史、各国の彫刻' },
  713: { ja: '木彫', en: '木彫' },
  714: { ja: '石彫', en: '石彫' },
  715: { ja: '金属彫刻、鋳造', en: '金属彫刻、鋳造' },
  716: { ja: '', en: '' },
  717: { ja: '粘土彫刻、塑造', en: '粘土彫刻、塑造' },
  718: { ja: '仏像', en: '仏像' },
  719: { ja: 'オブジェ', en: 'オブジェ' },
  720: { ja: '絵画', en: '絵画' },
  721: { ja: '日本画', en: '日本画' },
  722: { ja: '東洋画', en: '東洋画' },
  723: { ja: '洋画', en: '洋画' },
  724: { ja: '絵画材料・技法', en: '絵画材料・技法' },
  725: { ja: '素描、描画', en: '素描、描画' },
  726: { ja: '漫画、挿絵、児童画', en: '漫画、挿絵、児童画' },
  727: { ja: 'グラフィックデザイン、図案', en: 'グラフィックデザイン、図案' },
  728: { ja: '書、書道', en: '書、書道' },
  729: { ja: '', en: '' },
  730: { ja: '版画', en: '版画' },
  731: { ja: '版画材料・技法', en: '版画材料・技法' },
  732: { ja: '版画史、各国の版画', en: '版画史、各国の版画' },
  733: { ja: '木版画', en: '木版画' },
  734: { ja: '石版画（リトグラフ）', en: '石版画（リトグラフ）' },
  735: { ja: '銅版画、鋼版画', en: '銅版画、鋼版画' },
  736: { ja: 'リノリウム版画、ゴム版画', en: 'リノリウム版画、ゴム版画' },
  737: { ja: '写真版画、孔版画', en: '写真版画、孔版画' },
  738: { ja: '', en: '' },
  739: { ja: '印章、篆刻、印譜', en: '印章、篆刻、印譜' },
  740: { ja: '写真', en: '写真' },
  741: { ja: '', en: '' },
  742: { ja: '写真器械・材料', en: '写真器械・材料' },
  743: { ja: '撮影技術', en: '撮影技術' },
  744: { ja: '現像、印画', en: '現像、印画' },
  745: { ja: '複写技術', en: '複写技術' },
  746: { ja: '特殊写真', en: '特殊写真' },
  747: { ja: '写真の応用', en: '写真の応用' },
  748: { ja: '写真集', en: '写真集' },
  749: { ja: '印刷', en: '印刷' },
  750: { ja: '工芸', en: '工芸' },
  751: { ja: '陶磁工芸', en: '陶磁工芸' },
  752: { ja: '漆工芸', en: '漆工芸' },
  753: { ja: '染織工芸', en: '染織工芸' },
  754: { ja: '木竹工芸', en: '木竹工芸' },
  755: { ja: '宝石・牙角・皮革工芸', en: '宝石・牙角・皮革工芸' },
  756: { ja: '金工芸', en: '金工芸' },
  757: { ja: 'デザイン、装飾美術', en: 'デザイン、装飾美術' },
  758: { ja: '美術家具', en: '美術家具' },
  759: { ja: '人形、玩具', en: '人形、玩具' },
  760: { ja: '音楽', en: '音楽' },
  761: { ja: '音楽の一般理論、音楽学', en: '音楽の一般理論、音楽学' },
  762: { ja: '音楽史、各国の音楽', en: '音楽史、各国の音楽' },
  763: { ja: '楽器、器楽', en: '楽器、器楽' },
  764: { ja: '器楽合奏', en: '器楽合奏' },
  765: { ja: '宗教音楽、聖楽', en: '宗教音楽、聖楽' },
  766: { ja: '劇音楽', en: '劇音楽' },
  767: { ja: '声楽', en: '声楽' },
  768: { ja: '邦楽', en: '邦楽' },
  769: { ja: '舞踊、バレエ', en: '舞踊、バレエ' },
  770: { ja: '演劇', en: '演劇' },
  771: { ja: '劇場、演出、演技', en: '劇場、演出、演技' },
  772: { ja: '演劇史、各国の演劇', en: '演劇史、各国の演劇' },
  773: { ja: '能楽、狂言', en: '能楽、狂言' },
  774: { ja: '歌舞伎', en: '歌舞伎' },
  775: { ja: '各種の演劇', en: '各種の演劇' },
  776: { ja: '', en: '' },
  777: { ja: '人形劇', en: '人形劇' },
  778: { ja: '映画', en: '映画' },
  779: { ja: '大衆演芸', en: '大衆演芸' },
  780: { ja: 'スポーツ、体育', en: 'スポーツ、体育' },
  781: { ja: '体操、遊戯', en: '体操、遊戯' },
  782: { ja: '陸上競技', en: '陸上競技' },
  783: { ja: '球技', en: '球技' },
  784: { ja: '冬季競技', en: '冬季競技' },
  785: { ja: '水上競技', en: '水上競技' },
  786: { ja: '戸外レクリエーション', en: '戸外レクリエーション' },
  787: { ja: '釣魚、遊猟', en: '釣魚、遊猟' },
  788: { ja: '相撲、拳闘、競馬', en: '相撲、拳闘、競馬' },
  789: { ja: '武術', en: '武術' },
  790: { ja: '諸芸、娯楽', en: '諸芸、娯楽' },
  791: { ja: '茶道', en: '茶道' },
  792: { ja: '香道', en: '香道' },
  793: { ja: '花道（華道）', en: '花道（華道）' },
  794: { ja: 'ビリヤード', en: 'ビリヤード' },
  795: { ja: '囲碁', en: '囲碁' },
  796: { ja: '将棋', en: '将棋' },
  797: { ja: '射倖ゲーム', en: '射倖ゲーム' },
  798: { ja: 'その他の室内娯楽', en: 'その他の室内娯楽' },
  799: { ja: 'ダンス', en: 'ダンス' },
  800: { ja: '言語', en: '言語' },
  801: { ja: '言語学', en: '言語学' },
  802: { ja: '言語史・事情、言語政策', en: '言語史・事情、言語政策' },
  803: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  804: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  805: { ja: '逐次刊行物', en: '逐次刊行物' },
  806: { ja: '団体', en: '団体' },
  807: { ja: '研究法、指導法、言語教育', en: '研究法、指導法、言語教育' },
  808: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  809: { ja: '言語生活', en: '言語生活' },
  810: { ja: '日本語', en: '日本語' },
  811: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  812: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  813: { ja: '辞典', en: '辞典' },
  814: { ja: '語彙', en: '語彙' },
  815: { ja: '文法、語法', en: '文法、語法' },
  816: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  817: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  818: { ja: '方言、訛語', en: '方言、訛語' },
  819: { ja: '', en: '' },
  820: { ja: '中国語', en: '中国語' },
  821: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  822: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  823: { ja: '辞典', en: '辞典' },
  824: { ja: '語彙', en: '語彙' },
  825: { ja: '文法、語法', en: '文法、語法' },
  826: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  827: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  828: { ja: '方言、訛語', en: '方言、訛語' },
  829: { ja: 'その他の東洋の諸言語', en: 'その他の東洋の諸言語' },
  830: { ja: '英語', en: '英語' },
  831: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  832: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  833: { ja: '辞典', en: '辞典' },
  834: { ja: '語彙', en: '語彙' },
  835: { ja: '文法、語法', en: '文法、語法' },
  836: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  837: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  838: { ja: '方言、訛語', en: '方言、訛語' },
  839: { ja: '', en: '' },
  840: { ja: 'ドイツ語', en: 'ドイツ語' },
  841: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  842: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  843: { ja: '辞典', en: '辞典' },
  844: { ja: '語彙', en: '語彙' },
  845: { ja: '文法、語法', en: '文法、語法' },
  846: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  847: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  848: { ja: '方言、訛語', en: '方言、訛語' },
  849: { ja: 'その他のゲルマン諸語', en: 'その他のゲルマン諸語' },
  850: { ja: 'フランス語', en: 'フランス語' },
  851: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  852: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  853: { ja: '辞典', en: '辞典' },
  854: { ja: '語彙', en: '語彙' },
  855: { ja: '文法、語法', en: '文法、語法' },
  856: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  857: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  858: { ja: '方言、訛語', en: '方言、訛語' },
  859: { ja: 'プロバンス語', en: 'プロバンス語' },
  860: { ja: 'スペイン語', en: 'スペイン語' },
  861: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  862: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  863: { ja: '辞典', en: '辞典' },
  864: { ja: '語彙', en: '語彙' },
  865: { ja: '文法、語法', en: '文法、語法' },
  866: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  867: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  868: { ja: '方言、訛語', en: '方言、訛語' },
  869: { ja: 'ポルトガル語', en: 'ポルトガル語' },
  870: { ja: 'イタリア語', en: 'イタリア語' },
  871: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  872: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  873: { ja: '辞典', en: '辞典' },
  874: { ja: '語彙', en: '語彙' },
  875: { ja: '文法、語法', en: '文法、語法' },
  876: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  877: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  878: { ja: '方言、訛語', en: '方言、訛語' },
  879: { ja: 'その他のロマンス諸語', en: 'その他のロマンス諸語' },
  880: { ja: 'ロシア語', en: 'ロシア語' },
  881: { ja: '音声、音韻、文字', en: '音声、音韻、文字' },
  882: { ja: '語源、意味（語義）', en: '語源、意味（語義）' },
  883: { ja: '辞典', en: '辞典' },
  884: { ja: '語彙', en: '語彙' },
  885: { ja: '文法、語法', en: '文法、語法' },
  886: { ja: '文章、文体、作文', en: '文章、文体、作文' },
  887: { ja: '読本、解釈、会話', en: '読本、解釈、会話' },
  888: { ja: '方言、訛語', en: '方言、訛語' },
  889: { ja: 'その他のスラブ諸語', en: 'その他のスラブ諸語' },
  890: { ja: 'その他の諸言語', en: 'その他の諸言語' },
  891: { ja: 'ギリシア語', en: 'ギリシア語' },
  892: { ja: 'ラテン語', en: 'ラテン語' },
  893: { ja: 'その他のヨーロッパの諸言語', en: 'その他のヨーロッパの諸言語' },
  894: { ja: 'アフリカの諸言語', en: 'アフリカの諸言語' },
  895: { ja: 'アメリカの諸言語', en: 'アメリカの諸言語' },
  896: { ja: '', en: '' },
  897: { ja: 'オーストラリアの諸言語', en: 'オーストラリアの諸言語' },
  898: { ja: '', en: '' },
  899: { ja: '国際語（人工語）', en: '国際語（人工語）' },
  900: { ja: '文学', en: '文学' },
  901: { ja: '文学理論・作法', en: '文学理論・作法' },
  902: { ja: '文学史、文学思想史', en: '文学史、文学思想史' },
  903: { ja: '参考図書（レファレンスブック）', en: '参考図書（レファレンスブック）' },
  904: { ja: '論文集、評論集、講演集', en: '論文集、評論集、講演集' },
  905: { ja: '逐次刊行物', en: '逐次刊行物' },
  906: { ja: '団体', en: '団体' },
  907: { ja: '研究法、指導法、文学教育', en: '研究法、指導法、文学教育' },
  908: { ja: '叢書、全集、選集', en: '叢書、全集、選集' },
  909: { ja: '児童文学研究', en: '児童文学研究' },
  910: { ja: '日本文学', en: '日本文学' },
  911: { ja: '詩歌', en: '詩歌' },
  912: { ja: '戯曲', en: '戯曲' },
  913: { ja: '小説、物語', en: '小説、物語' },
  914: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  915: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  916: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  917: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  918: { ja: '作品集', en: '作品集' },
  919: { ja: '漢詩文、日本漢文学', en: '漢詩文、日本漢文学' },
  920: { ja: '中国文学', en: '中国文学' },
  921: { ja: '詩歌、韻文、詩文', en: '詩歌、韻文、詩文' },
  922: { ja: '戯曲', en: '戯曲' },
  923: { ja: '小説、物語', en: '小説、物語' },
  924: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  925: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  926: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  927: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  928: { ja: '作品集', en: '作品集' },
  929: { ja: 'その他の東洋文学', en: 'その他の東洋文学' },
  930: { ja: '英米文学', en: '英米文学' },
  931: { ja: '詩', en: '詩' },
  932: { ja: '戯曲', en: '戯曲' },
  933: { ja: '小説、物語', en: '小説、物語' },
  934: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  935: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  936: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  937: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  938: { ja: '作品集', en: '作品集' },
  939: { ja: 'アメリカ文学', en: 'アメリカ文学' },
  940: { ja: 'ドイツ文学', en: 'ドイツ文学' },
  941: { ja: '詩', en: '詩' },
  942: { ja: '戯曲', en: '戯曲' },
  943: { ja: '小説、物語', en: '小説、物語' },
  944: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  945: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  946: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  947: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  948: { ja: '作品集', en: '作品集' },
  949: { ja: 'その他のゲルマン文学', en: 'その他のゲルマン文学' },
  950: { ja: 'フランス文学', en: 'フランス文学' },
  951: { ja: '詩', en: '詩' },
  952: { ja: '戯曲', en: '戯曲' },
  953: { ja: '小説、物語', en: '小説、物語' },
  954: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  955: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  956: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  957: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  958: { ja: '作品集', en: '作品集' },
  959: { ja: 'プロバンス文学', en: 'プロバンス文学' },
  960: { ja: 'スペイン文学', en: 'スペイン文学' },
  961: { ja: '詩', en: '詩' },
  962: { ja: '戯曲', en: '戯曲' },
  963: { ja: '小説、物語', en: '小説、物語' },
  964: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  965: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  966: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  967: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  968: { ja: '作品集', en: '作品集' },
  969: { ja: 'ポルトガル文学', en: 'ポルトガル文学' },
  970: { ja: 'イタリア文学', en: 'イタリア文学' },
  971: { ja: '詩', en: '詩' },
  972: { ja: '戯曲', en: '戯曲' },
  973: { ja: '小説、物語', en: '小説、物語' },
  974: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  975: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  976: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  977: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  978: { ja: '作品集', en: '作品集' },
  979: { ja: 'その他のロマンス文学', en: 'その他のロマンス文学' },
  980: { ja: 'ロシア・ソビエト文学', en: 'ロシア・ソビエト文学' },
  981: { ja: '詩', en: '詩' },
  982: { ja: '戯曲', en: '戯曲' },
  983: { ja: '小説、物語', en: '小説、物語' },
  984: { ja: '評論、エッセイ、随筆', en: '評論、エッセイ、随筆' },
  985: { ja: '日記、書簡、紀行', en: '日記、書簡、紀行' },
  986: { ja: '記録、手記、ルポルタージュ', en: '記録、手記、ルポルタージュ' },
  987: { ja: '箴言、アフォリズム、寸言', en: '箴言、アフォリズム、寸言' },
  988: { ja: '作品集', en: '作品集' },
  989: { ja: 'その他のスラブ文学', en: 'その他のスラブ文学' },
  990: { ja: 'その他の諸言語文学', en: 'その他の諸言語文学' },
  991: { ja: 'ギリシア文学', en: 'ギリシア文学' },
  992: { ja: 'ラテン文学', en: 'ラテン文学' },
  993: { ja: 'その他のヨーロッパ文学', en: 'その他のヨーロッパ文学' },
  994: { ja: 'アフリカ文学', en: 'アフリカ文学' },
  995: { ja: 'アメリカ諸言語の文学', en: 'アメリカ諸言語の文学' },
  996: { ja: '', en: '' },
  997: { ja: 'オーストラリア諸言語の文学', en: 'オーストラリア諸言語の文学' },
  998: { ja: '', en: '' },
  999: { ja: '国際語（人工語）による文学', en: '国際語（人工語）による文学' },
}

export default ndcDivisionLabel

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FacetPanelKeyword = _resolveComponent("FacetPanelKeyword")!
  const _component_FacetPanelExclustion = _resolveComponent("FacetPanelExclustion")!
  const _component_FacetPanel = _resolveComponent("FacetPanel")!
  const _component_FacetPanelYear = _resolveComponent("FacetPanelYear")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FacetPanelKeyword),
    (_ctx.removeItems && _ctx.removeItems.length)
      ? (_openBlock(), _createBlock(_component_FacetPanelExclustion, { key: 0 }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facetContents, (content) => {
      return (_openBlock(), _createBlock(_component_FacetPanel, {
        key: content.name,
        facetItems: content.buckets,
        name: content.name
      }, null, 8, ["facetItems", "name"]))
    }), 128)),
    _createVNode(_component_FacetPanelYear)
  ], 64))
}

import { defineComponent, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import StringKeyObject from '@/data/@types/StringKeyObject'
import AppButton from '@/components/atoms/AppButton.vue'
import toLocale from '@/helpers/util/toLocale'
import { useI18n } from 'vue-i18n'

type State = {
  ariaSelected: boolean;
};
export default defineComponent({
  name: 'FacetPanel',
  components: {
    CloserController,
    AppInput,
    AppButton,
  },
  props: {
    from: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive<State>({
      ariaSelected: true,
    })

    const query = reactive({
      fromYear_facet: computed(() => route.query.fromYear_facet).value,
      toYear_facet: computed(() => route.query.toYear_facet).value,
    })

    const search = () => {
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
      }
      if (!where.fromYear_facet) delete where.fromYear_facet
      if (!where.toYear_facet) delete where.toYear_facet
      // 0詰めしないと正しく検索できないため
      where.fromYear_facet = where.fromYear_facet ? String(where.fromYear_facet).padStart(4, '0') : where.fromYear_facet
      where.toYear_facet = where.toYear_facet ? String(where.toYear_facet).padStart(4, '0') : where.toYear_facet
      router.push({ query: where })
      store.commit('UPDATE_SEARCH_STATE', false)
    }

    const clear = () => {
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
      }
      delete where.fromYear_facet
      delete where.toYear_facet
      router.push({ query: where })
    }

    const totalHits = computed(() => store.getters.totalHits)

    return {
      route,
      state,
      query,
      fromYear: query.fromYear_facet ? query.fromYear_facet : '',
      toYear: query.toYear_facet ? query.toYear_facet : '',
      totalHits,
      search,
      clear,
      toLocale,
      lang,
    }
  },
})


import { defineComponent, ref, computed } from 'vue'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import CloserController from '@/components/molecules/CloserController.vue'
import FacetPanelRecursiveList from '@/components/organisms/FacetPanelRecursiveList.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import toLocale from '@/helpers/util/toLocale'
import NdcDivisions from '@/data/master/NdcDivisions'
import { getLabelWithCode } from '@/data/master/MasterUtil'

export default defineComponent({
  components: {
    AppCheckbox,
    CloserController,
    FacetPanelRecursiveList,
  },
  props: {
    tree: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    checkedFacet: {
      type: Array,
      required: true,
    },
    search: {
      type: Function,
      required: false,
    },
  },
  setup (props) {
    const lang = useI18n().locale
    const store = useStore()
    const collectionMaster = computed(() => store.getters.CollectionMaster)

    const checkedFacetKeys = computed(() => props.checkedFacet)

    const show = ref(props.checkedFacet.length !== 0)

    return {
      show,
      selected: ref(false),
      lang,
      // TODO: 同じような処理書いてるのでまとめる
      getFacetLabel: (key: string) => {
        switch (props.name) {
          case 'digitizedContents':
          case 'onlinePublications':
            return collectionMaster.value[key] ? collectionMaster.value[key].content.name?.[lang.value] : ''
          case 'ndcDivisions':
            return getLabelWithCode(NdcDivisions, key, lang.value as 'ja' | 'en')
          default:
            return key
        }
      },
      checkedFacetKeys,
      toLocale,
    }
  },
})


import { computed, defineComponent, PropType } from 'vue'
import FacetPanel from '@/components/organisms/facet/panel/FacetPanel.vue'
import FacetPanelYear from '@/components/organisms/facet/panel/FacetPanelYear.vue'
import FacetPanelKeyword from '@/components/organisms/facet/panel/FacetPanelKeyword.vue'
import FacetPanelExclustion from '@/components/organisms/facet/panel/FacetPanelExclustion.vue'
import { FacetItem } from '@/data/@types/FacetItem'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'FacetDefault',
  components: {
    FacetPanel,
    FacetPanelYear,
    FacetPanelKeyword,
    FacetPanelExclustion,
  },
  props: {
    facetContents: {
      type: Object as PropType<FacetItem[]>,
      required: false,
    },
  },
  setup () {
    const store = useStore()
    const removeItems = computed(() => store.getters.RemoveItems)
    return {
      removeItems,
    }
  },
})


import { defineComponent, PropType } from 'vue'
import FacetPanel from '@/components/organisms/facet/panel/FacetPanel.vue'
import FacetPanelYear from '@/components/organisms/facet/panel/FacetPanelYear.vue'
import { FacetItem } from '@/data/@types/FacetItem'

export default defineComponent({
  name: 'FacetDefault',
  components: {
    FacetPanel,
    FacetPanelYear,
  },
  props: {
    facetContents: {
      type: Object as PropType<FacetItem[]>,
      required: false,
    },
  },
})

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FacetPanel = _resolveComponent("FacetPanel")!
  const _component_FacetPanelYear = _resolveComponent("FacetPanelYear")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facetContents, (content) => {
      return (_openBlock(), _createBlock(_component_FacetPanel, {
        key: content.name,
        facetItems: content.buckets,
        name: content.name
      }, null, 8, ["facetItems", "name"]))
    }), 128)),
    _createVNode(_component_FacetPanelYear)
  ], 64))
}

import { defineComponent, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppButton from '../atoms/AppButton.vue'
import DefaultFacet from '@/components/organisms/facet/pattern/default.vue'
import PictureFacet from '@/components/organisms/facet/pattern/picture.vue'
import { Bucket } from '@/data/@types/Aggregations'
import { FacetItem } from '@/data/@types/FacetItem'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { Collection } from '@/data/@types/Collection'
import { federalRegisterTypeList } from '@/data/selector/federalRegisterTypeList'
import { subjectKanpoList } from '@/data/selector/subjectKanpoList'
import { scrollToSearchResultTop } from '@/helpers/util/scrollUtil'

type State = {
  ariaSelected: boolean;
};
interface StringKeyCollection {
  [key: string]: Collection;
}
export default defineComponent({
  components: {
    PictureFacet,
    DefaultFacet,
    AppButton,
  },
  props: {
    collectionId: {
      type: String,
      required: false,
    },
    isVisibleFilteringLabel: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const totalHits = computed(() => store.getters.totalHits)
    const facetList = computed(() => store.getters.facetList)
    const isNarrowed = computed(() => store.getters.isNarrowed)
    const component = computed(() =>
      route.name === 'SearchImageResult' ? 'PictureFacet' : 'DefaultFacet'
    )

    const to = {
      name: 'SearchResult',
      query: route.query,
    }

    const state = reactive<State>({
      ariaSelected: false,
    })
    const collections: StringKeyCollection = store.getters.CollectionMaster
    const accessRestrictions: StringKeyObject = {
      internet: 1,
      ooc: 2,
      inlibrary: 3,
    }

    const collectionFacetList: StringKeyObject = store.getters.CollectionFacetList

    // 官報種別のソート順
    const federalRegisterTypeListOrder = federalRegisterTypeList.map((federalRegisterType: StringKeyObject) => federalRegisterType.value)
    // 記事種別のソート順
    const subjectKanpoListOrder = subjectKanpoList.map((subjectKanpo: StringKeyObject) => subjectKanpo.value)

    const filterFacetContentsByCollection = (key: string) => {
      if (!(key in store.getters.facetList)) return false

      // コレクション詳細検索以外 (共通)
      if (!props.collectionId) return Array.from<string>(collectionFacetList.A00001).includes(key)

      // コレクション詳細検索 (個別)
      const collectionId = props.collectionId
      if (collectionFacetList[collectionId]) return Array.from<string>(collectionFacetList[collectionId]).includes(key)

      // コレクション詳細検索 (デフォルト)
      return Array.from<string>(collectionFacetList.others).includes(key)
    }

    const sortContentsAndSetLayer = (name: string, buckets: Array<Bucket>) => {
      // 「デジタル化資料」「電子書籍・電子雑誌」のデータをツリー構造に更新
      if (name === 'digitizedContents' || name === 'onlinePublications') {
        updateBucketsOfCollection(buckets)
      }
      // 「閲覧できる場所」のソート
      if (name === 'accessRestrictions') {
        buckets.forEach((v) => { v.order = accessRestrictions[v.key] })
        buckets.sort((a, b) => (a.order ? a.order : 99999) - (b.order ? b.order : 99999))
      }
      // 「NDC分類」のレイヤー設定
      if (name === 'ndcDivisions') {
        updateBucketsOfNdc(buckets)
      }
      // 「官報種別」のソート
      if (name === 'officialBulletinType') {
        buckets.sort(function (x, y) {
          return federalRegisterTypeListOrder.indexOf(x.keyAsString) - federalRegisterTypeListOrder.indexOf(y.keyAsString)
        })
      }
      // 「記事種別」のソート
      if (name === 'articleType') {
        buckets.sort(function (x, y) {
          return subjectKanpoListOrder.indexOf(x.keyAsString) - subjectKanpoListOrder.indexOf(y.keyAsString)
        })
      }
    }

    const setQueryAsFacetItem = (item: Bucket, queryKey: string) => {
      let queryValues: Array<string> = Array.from([item.keyAsString])
      if (store.getters.narrowSearchQuery[queryKey]) {
        queryValues = Array.from(store.getters.narrowSearchQuery[queryKey])
        queryValues.push(item.keyAsString)
      }
      // この時点でqueryの中身は自分のみ || 自分 + 既に検索されているクエリ
      item.query = {}
      item.query[queryKey] = queryValues
    }

    const facetContents = computed<FacetItem[]>(() => {
      // 各ファセット項目ごとにFacetContentを作成
      return Array.from<string>(store.getters.FacetKeyList)
        .filter((key) => filterFacetContentsByCollection(key))
        .map((key) => {
          const name = store.getters.facetList[key].name
          const queryKey: string = store.getters.FacetNameQueryMap[name]
          let buckets: Array<Bucket> = Array.from<Bucket>(store.getters.facetList[key].buckets)
            // searchDisplay":"hidden"のコレクションは非表示にする
            .filter((v) => collections[v.key]?.content?.searchDisplay !== 'hidden')

          if (store.getters.narrowSearchQuery[queryKey]) {
            // 絞り込みのクエリとなるものをハイライトする
            buckets.forEach((v) => {
              v.isSelected = Array.from(store.getters.narrowSearchQuery[queryKey]).includes(v.keyAsString)
            })
          }
          // bucketsの要素ごとにqueryを設定
          buckets.forEach((v) => setQueryAsFacetItem(v, queryKey))

          // れきおん画面でoocは不要なので削除
          if (name === 'accessRestrictions' && route.meta.isRekion) {
            for (let index = buckets.length - 1; index >= 0; index--) {
              if (buckets[index].key === 'ooc') buckets.splice(index, 1)
            }
          }

          // 官報ファセットで表示するもののみを抽出
          // 官報種別
          if (name === 'officialBulletinType') {
            buckets = buckets.filter((v) => federalRegisterTypeListOrder.includes(v.keyAsString))
          }

          // 記事種別
          if (name === 'articleType') {
            buckets = buckets.filter((v) => subjectKanpoListOrder.includes(v.keyAsString))
          }

          // アイテムのソート&レイヤー設定
          // コレクションやNDCはツリー構造になるように更新する
          // ※重要：構造を変えるのでこのメソッドで一番最後に処理を行う必要
          sortContentsAndSetLayer(name, buckets)
          return new FacetItem(name, buckets)
        })
    })

    const resetNarrowingDown = () => {
      const query = resetFacet(route.query)
      // 「指定を解除」押下時に1ページ目を表示させる
      const where: StringKeyObject = {
        ...query,
        pageNum: undefined, // 0を直接指定すると動かないのでundefinedにすることで初期値の0を強制的に再現する
      }
      router.push({ query: where })
    }

    const resetFacet = (query: StringKeyObject) => {
      for (const param in query) {
        if (param.includes('facet')) {
          delete query[param]
        }
      }
      return query
    }

    /**
     * ファセット表示するコレクションをツリー構造に更新する
     */
    const updateBucketsOfCollection = (buckets: Array<Bucket>) => {
      buckets.forEach((bucket: any) => {
        // 指定したコレクション～上位コレクションまでの情報取得
        const collectionRelationList = store.getters.CollectionFamily(bucket.key)
        const collectionIdRelationList = collectionRelationList
          .filter((e: any) => {
            if (e.collectionId !== 'A00000' && e.collectionId !== 'B00000') {
              return e
            }
          })
          .map((e: any) => e.collectionId)
        // 下位コレクションのみ処理
        if (collectionIdRelationList.length !== 1) {
          const parentBucket = buckets.find(e => e.key === collectionIdRelationList[collectionIdRelationList.length - 2])
          // 対象コレクションがマスタに登録されていない可能性を考慮
          if (parentBucket) {
            if (parentBucket.children) {
              parentBucket.children.push(bucket)
            } else {
              parentBucket.children = [bucket]
            }
            // 削除用のフラグ
            bucket.delFlg = true
          }
        }
      })
      // フラグに基づいて不要なコレクション情報を削除
      const bucketLength = buckets.length
      for (let index = bucketLength - 1; index >= 0; index--) {
        if (buckets[index].delFlg) {
          buckets.splice(index, 1)
        }
      }
    }

    /**
     * ファセット表示するコレクションをツリー構造に更新する
     */

    const updateBucketsOfNdc = (buckets: Array<Bucket>) => {
      buckets.forEach((bucket: Bucket) => {
        // keyの桁数で判定する（1桁は最上位）
        // [0 > 01 > 011] のように階層を作成する
        if (bucket.key.length !== 1) {
          const parentNdc = buckets.find(e => e.key === bucket.key.slice(0, -1))
          if (parentNdc) {
            if (parentNdc.children) {
              parentNdc.children.push(bucket)
            } else {
              parentNdc.children = [bucket]
            }
            // 削除用のフラグ
            bucket.delFlg = true
          }
        }
      })
      // フラグに基づいて不要なコレクション情報を削除
      const bucketLength = buckets.length
      for (let index = bucketLength - 1; index >= 0; index--) {
        if (buckets[index].delFlg) {
          buckets.splice(index, 1)
        }
      }
    }

    const isServer = typeof window === 'undefined'
    const focusMove = () => {
      if (isServer) return
      // 検索結果一覧へ移動
      let searchResultElement
      if (route.name === 'SearchImageResult') {
        searchResultElement = document.querySelector('.the-search-result-main-item-list a') as HTMLElement
      } else {
        searchResultElement = document.querySelector('#the-selectbox-display-mode') as HTMLElement
      }
      searchResultElement.focus()
      scrollToSearchResultTop()
    }

    return {
      component,
      totalHits,
      facetList,
      collections,
      isNarrowed,
      to,
      state,
      facetContents,
      resetNarrowingDown,
      onClick: () => (state.ariaSelected = !state.ariaSelected),
      focusMove,
    }
  },
})

interface Master {[key: string]: {ja: string, en: string}}

const languageLabel: Master = {
  aar: { ja: 'アファル語', en: 'アファル語' },
  abk: { ja: 'アブハズ語', en: 'アブハズ語' },
  ace: { ja: 'アチェ語', en: 'アチェ語' },
  ach: { ja: 'アチョリ語', en: 'アチョリ語' },
  ada: { ja: 'アダグメ語', en: 'アダグメ語' },
  ady: { ja: 'アディゲ語', en: 'アディゲ語' },
  afa: { ja: 'アフロ・アジア語族(その他)', en: 'アフロ・アジア語族(その他)' },
  afh: { ja: 'アフリヒリ', en: 'アフリヒリ' },
  afr: { ja: 'アフリカーンス語', en: 'アフリカーンス語' },
  ain: { ja: 'アイヌ語', en: 'アイヌ語' },
  aka: { ja: 'アカン語', en: 'アカン語' },
  akk: { ja: 'アッカド語', en: 'アッカド語' },
  alb: { ja: 'アルバニア語', en: 'アルバニア語' },
  sqi: { ja: 'アルバニア語', en: 'アルバニア語' },
  ale: { ja: 'アリュート語', en: 'アリュート語' },
  alg: { ja: 'アルゴンキン語族', en: 'アルゴンキン語族' },
  alt: { ja: '南アルタイ語', en: '南アルタイ語' },
  amh: { ja: 'アムハラ語', en: 'アムハラ語' },
  ang: { ja: '古英語', en: '古英語' },
  anp: { ja: 'アンガ語', en: 'アンガ語' },
  apa: { ja: 'アパッチ語族', en: 'アパッチ語族' },
  ara: { ja: 'アラビア語', en: 'アラビア語' },
  arc: { ja: 'アラム語', en: 'アラム語' },
  arg: { ja: 'アラゴン語', en: 'アラゴン語' },
  arm: { ja: 'アルメニア語', en: 'アルメニア語' },
  hye: { ja: 'アルメニア語', en: 'アルメニア語' },
  arn: { ja: 'アラウコ語族', en: 'アラウコ語族' },
  arp: { ja: 'アラパホ語', en: 'アラパホ語' },
  art: { ja: '人工言語(その他)', en: '人工言語(その他)' },
  arw: { ja: 'アラワク語族', en: 'アラワク語族' },
  asm: { ja: 'アッサム語', en: 'アッサム語' },
  ast: { ja: 'アストゥリアス語', en: 'アストゥリアス語' },
  ath: { ja: 'アサパスカ語族', en: 'アサパスカ語族' },
  aus: { ja: 'オーストラリア先住民語', en: 'オーストラリア先住民語' },
  ava: { ja: 'アバール語', en: 'アバール語' },
  ave: { ja: 'アベスタ語', en: 'アベスタ語' },
  awa: { ja: 'アワディー語', en: 'アワディー語' },
  aym: { ja: 'アイマラ語', en: 'アイマラ語' },
  aze: { ja: 'アゼルバイジャン語', en: 'アゼルバイジャン語' },
  bad: { ja: 'バンダ語', en: 'バンダ語' },
  bai: { ja: 'バミレケ諸語', en: 'バミレケ諸語' },
  bak: { ja: 'バシュキール語', en: 'バシュキール語' },
  bal: { ja: 'バルーチー語', en: 'バルーチー語' },
  bam: { ja: 'バンバラ語', en: 'バンバラ語' },
  ban: { ja: 'バリ語', en: 'バリ語' },
  baq: { ja: 'バスク語', en: 'バスク語' },
  eus: { ja: 'バスク語', en: 'バスク語' },
  bas: { ja: 'バサ語', en: 'バサ語' },
  bat: { ja: 'バルト語派(その他)', en: 'バルト語派(その他)' },
  bej: { ja: 'ベジャ語', en: 'ベジャ語' },
  bel: { ja: '白ロシア語；ベラルーシ語', en: '白ロシア語；ベラルーシ語' },
  bem: { ja: 'ベンバ語', en: 'ベンバ語' },
  ben: { ja: 'ベンガル語', en: 'ベンガル語' },
  ber: { ja: 'ベルベル語派(その他)', en: 'ベルベル語派(その他)' },
  bho: { ja: 'ボージュプリー語', en: 'ボージュプリー語' },
  bih: { ja: 'ビハール語', en: 'ビハール語' },
  bik: { ja: 'ビコル小語群', en: 'ビコル小語群' },
  bin: { ja: 'ビニ語', en: 'ビニ語' },
  bis: { ja: 'ビスラマ語', en: 'ビスラマ語' },
  bla: { ja: 'ブラックフット語', en: 'ブラックフット語' },
  bnt: { ja: 'バントゥ諸語(その他)', en: 'バントゥ諸語(その他)' },
  bos: { ja: 'ボスニア語', en: 'ボスニア語' },
  bra: { ja: 'ブラジ語', en: 'ブラジ語' },
  bre: { ja: 'ブルトン語', en: 'ブルトン語' },
  btk: { ja: 'バタク語(インドネシア)', en: 'バタク語(インドネシア)' },
  bua: { ja: 'ブリヤート語', en: 'ブリヤート語' },
  bug: { ja: 'ブギス語', en: 'ブギス語' },
  bul: { ja: 'ブルガリア語', en: 'ブルガリア語' },
  bur: { ja: 'ビルマ語；ミャンマー語', en: 'ビルマ語；ミャンマー語' },
  mya: { ja: 'ビルマ語；ミャンマー語', en: 'ビルマ語；ミャンマー語' },
  byn: { ja: 'ビリン語', en: 'ビリン語' },
  cad: { ja: 'カド語', en: 'カド語' },
  cai: { ja: '中米インディアン諸語(その他)', en: '中米インディアン諸語(その他)' },
  car: { ja: 'カリブ語', en: 'カリブ語' },
  cat: { ja: 'カタロニア語', en: 'カタロニア語' },
  cau: { ja: 'カフカース諸語(その他)', en: 'カフカース諸語(その他)' },
  ceb: { ja: 'セブアノ語', en: 'セブアノ語' },
  cel: { ja: 'ケルト語派(その他)', en: 'ケルト語派(その他)' },
  cha: { ja: 'チャモロ語', en: 'チャモロ語' },
  chb: { ja: 'チブチャ語族', en: 'チブチャ語族' },
  che: { ja: 'チェチェン語', en: 'チェチェン語' },
  chg: { ja: 'チャガタイ語', en: 'チャガタイ語' },
  chi: { ja: '中国語', en: '中国語' },
  zho: { ja: '中国語', en: '中国語' },
  chk: { ja: 'トラック語', en: 'トラック語' },
  chm: { ja: 'マリ語', en: 'マリ語' },
  chn: { ja: 'チヌーク・ジャーゴン', en: 'チヌーク・ジャーゴン' },
  cho: { ja: 'チョクトー語', en: 'チョクトー語' },
  chp: { ja: 'チペワイアン語', en: 'チペワイアン語' },
  chr: { ja: 'チェロキー語', en: 'チェロキー語' },
  chu: { ja: '教会スラブ語', en: '教会スラブ語' },
  chv: { ja: 'チュワシュ語', en: 'チュワシュ語' },
  chy: { ja: 'シャイアン語', en: 'シャイアン語' },
  cmc: { ja: 'チャム諸語', en: 'チャム諸語' },
  cop: { ja: 'コプト語', en: 'コプト語' },
  cor: { ja: 'コーンウォール語', en: 'コーンウォール語' },
  cos: { ja: 'コルシカ語', en: 'コルシカ語' },
  cpe: { ja: '英語系クレオル諸語及びピジン語(その他)', en: '英語系クレオル諸語及びピジン語(その他)' },
  cpf: { ja: 'フランス語系クレオル諸語及びピジン語(その他)', en: 'フランス語系クレオル諸語及びピジン語(その他)' },
  cpp: { ja: 'ポルトガル語系クレオル諸語及びピジン語(その他)', en: 'ポルトガル語系クレオル諸語及びピジン語(その他)' },
  cre: { ja: 'クリー語', en: 'クリー語' },
  crh: { ja: 'クリム・タタール語；クリム・トルコ語', en: 'クリム・タタール語；クリム・トルコ語' },
  crp: { ja: 'クレオル諸語及びピジン語(その他)', en: 'クレオル諸語及びピジン語(その他)' },
  csb: { ja: 'カシューブ語', en: 'カシューブ語' },
  cus: { ja: 'クシュ語派(その他)', en: 'クシュ語派(その他)' },
  cze: { ja: 'チェコ語', en: 'チェコ語' },
  ces: { ja: 'チェコ語', en: 'チェコ語' },
  dak: { ja: 'ダコタ語', en: 'ダコタ語' },
  dan: { ja: 'デンマーク語', en: 'デンマーク語' },
  dar: { ja: 'ダルグワ語', en: 'ダルグワ語' },
  day: { ja: 'ダヤク語', en: 'ダヤク語' },
  del: { ja: 'デラウエア語', en: 'デラウエア語' },
  den: { ja: 'スレイビー語', en: 'スレイビー語' },
  dgr: { ja: 'ドグリブ語', en: 'ドグリブ語' },
  din: { ja: 'ディンカ語', en: 'ディンカ語' },
  div: { ja: 'ディベヒ語；モルジブ語', en: 'ディベヒ語；モルジブ語' },
  doi: { ja: 'ドーグリー語', en: 'ドーグリー語' },
  dra: { ja: 'ドラビダ語族(その他)', en: 'ドラビダ語族(その他)' },
  dsb: { ja: '下ソルブ語', en: '下ソルブ語' },
  dua: { ja: 'ドゥアラ語', en: 'ドゥアラ語' },
  dum: { ja: '中期オランダ語', en: '中期オランダ語' },
  dut: { ja: 'オランダ語', en: 'オランダ語' },
  nld: { ja: 'オランダ語', en: 'オランダ語' },
  dyu: { ja: 'ジュラ語', en: 'ジュラ語' },
  dzo: { ja: 'ゾンカ語；ブータン語', en: 'ゾンカ語；ブータン語' },
  efi: { ja: 'エフィック語', en: 'エフィック語' },
  egy: { ja: '古代エジプト語', en: '古代エジプト語' },
  eka: { ja: 'エカジュク語', en: 'エカジュク語' },
  elx: { ja: 'エラム語', en: 'エラム語' },
  eng: { ja: '英語', en: '英語' },
  enm: { ja: '中期英語', en: '中期英語' },
  epo: { ja: 'エスペラント語', en: 'エスペラント語' },
  est: { ja: 'エストニア語', en: 'エストニア語' },
  ewe: { ja: 'エウェ語群；エベ語群', en: 'エウェ語群；エベ語群' },
  ewo: { ja: 'エウォンド語', en: 'エウォンド語' },
  fan: { ja: 'ファン語', en: 'ファン語' },
  fao: { ja: 'フェーロー語', en: 'フェーロー語' },
  fat: { ja: 'ファンテ語', en: 'ファンテ語' },
  fij: { ja: 'フィジー語', en: 'フィジー語' },
  fil: { ja: 'フィリピノ語；ピリピノ語', en: 'フィリピノ語；ピリピノ語' },
  fin: { ja: 'フィンランド語', en: 'フィンランド語' },
  fiu: { ja: 'フィン・ウゴル語派(その他)', en: 'フィン・ウゴル語派(その他)' },
  fon: { ja: 'フォン語', en: 'フォン語' },
  fre: { ja: 'フランス語', en: 'フランス語' },
  fra: { ja: 'フランス語', en: 'フランス語' },
  frm: { ja: '中期フランス語', en: '中期フランス語' },
  fro: { ja: '古フランス語', en: '古フランス語' },
  frr: { ja: '北フリジア語', en: '北フリジア語' },
  frs: { ja: '東フリジア語', en: '東フリジア語' },
  fry: { ja: 'フリジア語；西フリジア語', en: 'フリジア語；西フリジア語' },
  ful: { ja: 'フラニ語；フルフルデ語', en: 'フラニ語；フルフルデ語' },
  fur: { ja: 'フリウリ語', en: 'フリウリ語' },
  gaa: { ja: 'ガー語', en: 'ガー語' },
  gay: { ja: 'ガヨ語', en: 'ガヨ語' },
  gba: { ja: 'グバヤ方言群', en: 'グバヤ方言群' },
  gem: { ja: 'ゲルマン語派(その他)', en: 'ゲルマン語派(その他)' },
  geo: { ja: 'グルジア語', en: 'グルジア語' },
  kat: { ja: 'グルジア語', en: 'グルジア語' },
  ger: { ja: 'ドイツ語', en: 'ドイツ語' },
  deu: { ja: 'ドイツ語', en: 'ドイツ語' },
  gez: { ja: 'ゲエズ語', en: 'ゲエズ語' },
  gil: { ja: 'キリバス語；ギルバート語', en: 'キリバス語；ギルバート語' },
  gla: { ja: '高地スコットランド・ゲール語', en: '高地スコットランド・ゲール語' },
  gle: { ja: 'アイルランド語', en: 'アイルランド語' },
  glg: { ja: 'ガリシア語', en: 'ガリシア語' },
  glv: { ja: 'マン島語', en: 'マン島語' },
  gmh: { ja: '中高ドイツ語', en: '中高ドイツ語' },
  goh: { ja: '古高ドイツ語', en: '古高ドイツ語' },
  gon: { ja: 'ゴーンディー語', en: 'ゴーンディー語' },
  gor: { ja: 'ゴロンタロ語', en: 'ゴロンタロ語' },
  got: { ja: 'ゴート語', en: 'ゴート語' },
  grb: { ja: 'グレボ小語群', en: 'グレボ小語群' },
  grc: { ja: '古代ギリシア語', en: '古代ギリシア語' },
  gre: { ja: '現代ギリシア語', en: '現代ギリシア語' },
  ell: { ja: '現代ギリシア語', en: '現代ギリシア語' },
  grn: { ja: 'グァラニ語；ワラニー語', en: 'グァラニ語；ワラニー語' },
  gsw: { ja: 'スイス・ドイツ語；アレマン語', en: 'スイス・ドイツ語；アレマン語' },
  guj: { ja: 'グジャラート語', en: 'グジャラート語' },
  gwi: { ja: 'クチン語', en: 'クチン語' },
  hai: { ja: 'ハイダ語', en: 'ハイダ語' },
  hat: { ja: 'ハイチ語；ハイチ・クレオール語', en: 'ハイチ語；ハイチ・クレオール語' },
  hau: { ja: 'ハウサ語', en: 'ハウサ語' },
  haw: { ja: 'ハワイ語', en: 'ハワイ語' },
  heb: { ja: 'ヘブライ語', en: 'ヘブライ語' },
  her: { ja: 'ヘレロ語', en: 'ヘレロ語' },
  hil: { ja: 'ヒリガイノン語', en: 'ヒリガイノン語' },
  him: { ja: 'ヒマチャリー語', en: 'ヒマチャリー語' },
  hin: { ja: 'ヒンディー語', en: 'ヒンディー語' },
  hit: { ja: 'ヒッタイト語', en: 'ヒッタイト語' },
  hmn: { ja: 'フモン語', en: 'フモン語' },
  hmo: { ja: 'ヒリモトゥ語', en: 'ヒリモトゥ語' },
  hsb: { ja: '上ソルブ語', en: '上ソルブ語' },
  hun: { ja: 'ハンガリー語；マジャル語', en: 'ハンガリー語；マジャル語' },
  hup: { ja: 'フーパ語', en: 'フーパ語' },
  iba: { ja: 'イバン語', en: 'イバン語' },
  ibo: { ja: 'イグボ語；イボ語', en: 'イグボ語；イボ語' },
  ice: { ja: 'アイスランド語', en: 'アイスランド語' },
  isl: { ja: 'アイスランド語', en: 'アイスランド語' },
  ido: { ja: 'イド語', en: 'イド語' },
  iii: { ja: '彝語', en: '彝語' },
  ijo: { ja: 'イジョー方言群', en: 'イジョー方言群' },
  iku: { ja: 'イヌイット語；エスキモー語', en: 'イヌイット語；エスキモー語' },
  ile: { ja: 'インターリング', en: 'インターリング' },
  ilo: { ja: 'イロカノ語', en: 'イロカノ語' },
  ina: { ja: 'インターリンガ語', en: 'インターリンガ語' },
  inc: { ja: 'インド語派(その他)', en: 'インド語派(その他)' },
  ind: { ja: 'インドネシア語', en: 'インドネシア語' },
  ine: { ja: 'インド・ヨーロッパ語族(その他)', en: 'インド・ヨーロッパ語族(その他)' },
  inh: { ja: 'イングシ語', en: 'イングシ語' },
  ipk: { ja: 'イヌピアック語', en: 'イヌピアック語' },
  ira: { ja: 'イラン語派(その他)', en: 'イラン語派(その他)' },
  iro: { ja: 'イロコイ語族', en: 'イロコイ語族' },
  ita: { ja: 'イタリア語', en: 'イタリア語' },
  jav: { ja: 'ジャワ語', en: 'ジャワ語' },
  jaw: { ja: 'ジャワ語', en: 'ジャワ語' },
  jbo: { ja: 'ロジバン語', en: 'ロジバン語' },
  jpn: { ja: '日本語', en: '日本語' },
  jpr: { ja: 'ユダヤ・ペルシア語', en: 'ユダヤ・ペルシア語' },
  jrb: { ja: 'ユダヤ・アラビア語', en: 'ユダヤ・アラビア語' },
  kaa: { ja: 'カラカルパク語', en: 'カラカルパク語' },
  kab: { ja: 'カビール語', en: 'カビール語' },
  kac: { ja: 'カチン語', en: 'カチン語' },
  kal: { ja: 'グリーンランド語', en: 'グリーンランド語' },
  kam: { ja: 'カンバ語', en: 'カンバ語' },
  kan: { ja: 'カンナダ語', en: 'カンナダ語' },
  kar: { ja: 'カレン語群', en: 'カレン語群' },
  kas: { ja: 'カシュミール語', en: 'カシュミール語' },
  kau: { ja: 'カヌリ語', en: 'カヌリ語' },
  kaw: { ja: 'カウィ語', en: 'カウィ語' },
  kaz: { ja: 'カザフ語', en: 'カザフ語' },
  kbd: { ja: 'カバルド語', en: 'カバルド語' },
  kha: { ja: 'カシ語', en: 'カシ語' },
  khi: { ja: 'コイサン語族(その他)', en: 'コイサン語族(その他)' },
  khm: { ja: 'カンボジア語；クメール語', en: 'カンボジア語；クメール語' },
  kho: { ja: 'コータン語', en: 'コータン語' },
  kik: { ja: 'キクユ語', en: 'キクユ語' },
  kin: { ja: 'ルワンダ語', en: 'ルワンダ語' },
  kir: { ja: 'キルギス語', en: 'キルギス語' },
  kmb: { ja: 'キンブンドゥ語', en: 'キンブンドゥ語' },
  kok: { ja: 'コーンカニー語', en: 'コーンカニー語' },
  kom: { ja: 'コミ語', en: 'コミ語' },
  kon: { ja: 'コンゴ語', en: 'コンゴ語' },
  kor: { ja: '韓国語；朝鮮語', en: '韓国語；朝鮮語' },
  kos: { ja: 'コシャエ語', en: 'コシャエ語' },
  kpe: { ja: 'クペレ語', en: 'クペレ語' },
  krc: { ja: 'カラチャイ・バルカル語', en: 'カラチャイ・バルカル語' },
  krl: { ja: 'カレリア語', en: 'カレリア語' },
  kro: { ja: 'クルー諸語', en: 'クルー諸語' },
  kru: { ja: 'クルク語', en: 'クルク語' },
  kua: { ja: 'クワニャマ語', en: 'クワニャマ語' },
  kum: { ja: 'クムク語', en: 'クムク語' },
  kur: { ja: 'クルド語', en: 'クルド語' },
  kut: { ja: 'クーテナイ語', en: 'クーテナイ語' },
  lad: { ja: 'ユダヤ・スペイン語', en: 'ユダヤ・スペイン語' },
  lah: { ja: 'ラーンダー語', en: 'ラーンダー語' },
  lam: { ja: 'ランバ語', en: 'ランバ語' },
  lao: { ja: 'ラオ語', en: 'ラオ語' },
  lat: { ja: 'ラテン語', en: 'ラテン語' },
  lav: { ja: 'ラトビア語', en: 'ラトビア語' },
  lez: { ja: 'レズギ語', en: 'レズギ語' },
  lim: { ja: 'リンブルガー語', en: 'リンブルガー語' },
  lin: { ja: 'リンガラ語', en: 'リンガラ語' },
  lit: { ja: 'リトアニア語', en: 'リトアニア語' },
  lol: { ja: 'モンゴ語', en: 'モンゴ語' },
  loz: { ja: 'ロズィ語', en: 'ロズィ語' },
  ltz: { ja: 'ルクセンブルク語', en: 'ルクセンブルク語' },
  lua: { ja: 'ルバ・ルルア小語群', en: 'ルバ・ルルア小語群' },
  lub: { ja: 'キルバ語', en: 'キルバ語' },
  lug: { ja: 'ガンダ語', en: 'ガンダ語' },
  lui: { ja: 'ルイセーニョ語', en: 'ルイセーニョ語' },
  lun: { ja: 'ルンダ語', en: 'ルンダ語' },
  luo: { ja: 'ルオ語', en: 'ルオ語' },
  lus: { ja: 'ルシャイ語', en: 'ルシャイ語' },
  mac: { ja: 'マケドニア語', en: 'マケドニア語' },
  mkd: { ja: 'マケドニア語', en: 'マケドニア語' },
  mad: { ja: 'マドゥラ語', en: 'マドゥラ語' },
  mag: { ja: 'マガヒー語', en: 'マガヒー語' },
  mah: { ja: 'マーシャル語', en: 'マーシャル語' },
  mai: { ja: 'マイティリー語', en: 'マイティリー語' },
  mak: { ja: 'マカッサル語', en: 'マカッサル語' },
  mal: { ja: 'マラヤーラム語', en: 'マラヤーラム語' },
  man: { ja: 'マンデ語；マンディンゴ語', en: 'マンデ語；マンディンゴ語' },
  mao: { ja: 'マオリ語', en: 'マオリ語' },
  mri: { ja: 'マオリ語', en: 'マオリ語' },
  map: { ja: 'オーストロネシア語族(その他)', en: 'オーストロネシア語族(その他)' },
  mar: { ja: 'マラーティー語', en: 'マラーティー語' },
  mas: { ja: 'マサイ語', en: 'マサイ語' },
  may: { ja: 'マレー語', en: 'マレー語' },
  msa: { ja: 'マレー語', en: 'マレー語' },
  mdf: { ja: 'モクシャ語', en: 'モクシャ語' },
  mdr: { ja: 'マンダル語', en: 'マンダル語' },
  men: { ja: 'メンデ語', en: 'メンデ語' },
  mga: { ja: '中期アイルランド語', en: '中期アイルランド語' },
  mic: { ja: 'ミクマック語', en: 'ミクマック語' },
  min: { ja: 'ミナンカバウ語', en: 'ミナンカバウ語' },
  mis: { ja: 'その他の言語', en: 'その他の言語' },
  mkh: { ja: 'モン・クメール語派(その他)', en: 'モン・クメール語派(その他)' },
  mlg: { ja: 'マラガシ語', en: 'マラガシ語' },
  mlt: { ja: 'マルタ語', en: 'マルタ語' },
  mnc: { ja: '満州語', en: '満州語' },
  mni: { ja: 'マニプル語', en: 'マニプル語' },
  mno: { ja: 'マノボ語族', en: 'マノボ語族' },
  moh: { ja: 'モホーク語', en: 'モホーク語' },
  mol: { ja: 'モルダビア語', en: 'モルダビア語' },
  mon: { ja: '満州語；モンゴル語', en: '満州語；モンゴル語' },
  mos: { ja: 'モシ語', en: 'モシ語' },
  mul: { ja: '複数言語', en: '複数言語' },
  mun: { ja: 'ムンダー諸語', en: 'ムンダー諸語' },
  mus: { ja: 'クリーク語', en: 'クリーク語' },
  mwl: { ja: 'ミランダ語', en: 'ミランダ語' },
  mwr: { ja: 'マールワーリー語', en: 'マールワーリー語' },
  myn: { ja: 'マヤ語族', en: 'マヤ語族' },
  myv: { ja: 'エルジャ語', en: 'エルジャ語' },
  nah: { ja: 'ナワトル語', en: 'ナワトル語' },
  nai: { ja: '北米インディアン諸語(その他)', en: '北米インディアン諸語(その他)' },
  nap: { ja: 'ナポリ語', en: 'ナポリ語' },
  nau: { ja: 'ナウル語', en: 'ナウル語' },
  nav: { ja: 'ナバホ語', en: 'ナバホ語' },
  nbl: { ja: '南ンデベレ語', en: '南ンデベレ語' },
  nde: { ja: '北ンデベレ語', en: '北ンデベレ語' },
  ndo: { ja: 'ンドゥンガ語', en: 'ンドゥンガ語' },
  nds: { ja: '低地ドイツ語', en: '低地ドイツ語' },
  nep: { ja: 'ネパール語', en: 'ネパール語' },
  new: { ja: 'ネワール語', en: 'ネワール語' },
  nia: { ja: 'ニアス語', en: 'ニアス語' },
  nic: { ja: 'ニジェール・コルドファン語族(その他)', en: 'ニジェール・コルドファン語族(その他)' },
  niu: { ja: 'ニウエー語', en: 'ニウエー語' },
  nno: { ja: 'ニューノシュク', en: 'ニューノシュク' },
  nob: { ja: 'ブークモール', en: 'ブークモール' },
  nog: { ja: 'ノガイ語', en: 'ノガイ語' },
  non: { ja: '古ノルド語', en: '古ノルド語' },
  nor: { ja: 'ノルウェー語', en: 'ノルウェー語' },
  nqo: { ja: 'ンコ語', en: 'ンコ語' },
  nso: { ja: '北ソト語', en: '北ソト語' },
  nub: { ja: 'ヌビア語群', en: 'ヌビア語群' },
  nwc: { ja: '古典ネワール語', en: '古典ネワール語' },
  nya: { ja: 'チェワ語；ニャンジャ語', en: 'チェワ語；ニャンジャ語' },
  nym: { ja: 'ニャムウェズィ語', en: 'ニャムウェズィ語' },
  nyn: { ja: 'ニャンコレ語', en: 'ニャンコレ語' },
  nyo: { ja: 'ニョロ語', en: 'ニョロ語' },
  nzi: { ja: 'ンジマ語', en: 'ンジマ語' },
  oci: { ja: 'オック語', en: 'オック語' },
  oji: { ja: 'オジブワ語', en: 'オジブワ語' },
  ori: { ja: 'オリヤー語', en: 'オリヤー語' },
  orm: { ja: 'オロモ語；ガッラ語', en: 'オロモ語；ガッラ語' },
  osa: { ja: 'オセージ語', en: 'オセージ語' },
  oss: { ja: 'オセット語', en: 'オセット語' },
  ota: { ja: 'オスマン・トルコ語', en: 'オスマン・トルコ語' },
  oto: { ja: 'オトミ語', en: 'オトミ語' },
  paa: { ja: 'パプア諸語(その他)', en: 'パプア諸語(その他)' },
  pag: { ja: 'パンガシナン語', en: 'パンガシナン語' },
  pal: { ja: '中期ペルシア語；パフラビ語', en: '中期ペルシア語；パフラビ語' },
  pam: { ja: 'カパンパンガン語', en: 'カパンパンガン語' },
  pan: { ja: 'パンジャーブ語', en: 'パンジャーブ語' },
  pap: { ja: 'パピアメント語', en: 'パピアメント語' },
  pau: { ja: 'パラオ語', en: 'パラオ語' },
  peo: { ja: '古代ペルシア語', en: '古代ペルシア語' },
  per: { ja: 'ペルシア語', en: 'ペルシア語' },
  fas: { ja: 'ペルシア語', en: 'ペルシア語' },
  phi: { ja: 'フィリピン諸語(その他)', en: 'フィリピン諸語(その他)' },
  phn: { ja: 'フェニキア語', en: 'フェニキア語' },
  pli: { ja: 'パーリ語', en: 'パーリ語' },
  pol: { ja: 'ポーランド語', en: 'ポーランド語' },
  pon: { ja: 'ポナペ語', en: 'ポナペ語' },
  por: { ja: 'ポルトガル語', en: 'ポルトガル語' },
  pra: { ja: 'プラークリット語', en: 'プラークリット語' },
  pro: { ja: 'プロバンス語', en: 'プロバンス語' },
  pus: { ja: 'パシュトー語', en: 'パシュトー語' },
  'qaa-qtz': { ja: '局地用', en: '局地用' },
  que: { ja: 'ケチュア語', en: 'ケチュア語' },
  raj: { ja: 'ラージャスターン語', en: 'ラージャスターン語' },
  rap: { ja: 'ラパヌイ語', en: 'ラパヌイ語' },
  rar: { ja: 'ラロトンガ語', en: 'ラロトンガ語' },
  roa: { ja: 'ロマンス諸語(その他)', en: 'ロマンス諸語(その他)' },
  roh: { ja: 'レト・ロマンス語；ロマンシュ語', en: 'レト・ロマンス語；ロマンシュ語' },
  rom: { ja: 'ロマーニー語', en: 'ロマーニー語' },
  rum: { ja: 'ルーマニア語', en: 'ルーマニア語' },
  ron: { ja: 'ルーマニア語', en: 'ルーマニア語' },
  run: { ja: 'ルンディ語', en: 'ルンディ語' },
  rup: { ja: 'アルーマニア語；マケド・ルーマニア語', en: 'アルーマニア語；マケド・ルーマニア語' },
  rus: { ja: 'ロシア語', en: 'ロシア語' },
  sad: { ja: 'サンダウェ語', en: 'サンダウェ語' },
  sag: { ja: 'サンゴ語', en: 'サンゴ語' },
  sah: { ja: 'ヤクート語', en: 'ヤクート語' },
  sai: { ja: '南米インディアン諸語(その他)', en: '南米インディアン諸語(その他)' },
  sal: { ja: 'セイリッシュ語族', en: 'セイリッシュ語族' },
  sam: { ja: 'サマリア・アラム語', en: 'サマリア・アラム語' },
  san: { ja: 'サンスクリット語', en: 'サンスクリット語' },
  sas: { ja: 'ササク語', en: 'ササク語' },
  sat: { ja: 'サンタール語', en: 'サンタール語' },
  scc: { ja: 'セルビア語', en: 'セルビア語' },
  srp: { ja: 'セルビア語', en: 'セルビア語' },
  scn: { ja: 'シチリア語', en: 'シチリア語' },
  sco: { ja: '低地スコットランド・ゲール語', en: '低地スコットランド・ゲール語' },
  scr: { ja: 'クロアチア語', en: 'クロアチア語' },
  hrv: { ja: 'クロアチア語', en: 'クロアチア語' },
  sel: { ja: 'セリクプ語', en: 'セリクプ語' },
  sem: { ja: 'セム語族(その他)', en: 'セム語族(その他)' },
  sga: { ja: '古アイルランド語', en: '古アイルランド語' },
  sgn: { ja: '手話', en: '手話' },
  shn: { ja: 'シャン語', en: 'シャン語' },
  sid: { ja: 'シダモ語', en: 'シダモ語' },
  sin: { ja: 'シンハラ語', en: 'シンハラ語' },
  sio: { ja: 'スー語族', en: 'スー語族' },
  sit: { ja: 'シナ・チベット語族(その他)', en: 'シナ・チベット語族(その他)' },
  sla: { ja: 'スラブ語派(その他)', en: 'スラブ語派(その他)' },
  slo: { ja: 'スロバキア語', en: 'スロバキア語' },
  slk: { ja: 'スロバキア語', en: 'スロバキア語' },
  slv: { ja: 'スロベニア語', en: 'スロベニア語' },
  sma: { ja: '南サーミ語', en: '南サーミ語' },
  sme: { ja: '北サーミ語', en: '北サーミ語' },
  smi: { ja: 'サーミ語(その他)', en: 'サーミ語(その他)' },
  smj: { ja: 'ルレ・サーミ語', en: 'ルレ・サーミ語' },
  smn: { ja: 'イナリ・サーミ語', en: 'イナリ・サーミ語' },
  smo: { ja: 'サモア語', en: 'サモア語' },
  sms: { ja: 'スコルト・サーミ語', en: 'スコルト・サーミ語' },
  sna: { ja: 'ショナ語', en: 'ショナ語' },
  snd: { ja: 'シンド語', en: 'シンド語' },
  snk: { ja: 'ソニンケ語', en: 'ソニンケ語' },
  sog: { ja: 'ソグド語', en: 'ソグド語' },
  som: { ja: 'ソマリ語', en: 'ソマリ語' },
  son: { ja: 'ソンガイ語', en: 'ソンガイ語' },
  sot: { ja: '南ソト語', en: '南ソト語' },
  spa: { ja: 'スペイン語；カスティーヤ語', en: 'スペイン語；カスティーヤ語' },
  srd: { ja: 'サルデーニャ語', en: 'サルデーニャ語' },
  srn: { ja: 'スラナン語', en: 'スラナン語' },
  srr: { ja: 'セレル語', en: 'セレル語' },
  ssa: { ja: 'ナイル・サハラ言語群(その他)', en: 'ナイル・サハラ言語群(その他)' },
  ssw: { ja: 'スワティ語', en: 'スワティ語' },
  suk: { ja: 'スクマ語', en: 'スクマ語' },
  sun: { ja: 'スンダ語', en: 'スンダ語' },
  sus: { ja: 'スス語', en: 'スス語' },
  sux: { ja: 'シュメール語', en: 'シュメール語' },
  swa: { ja: 'スワヒリ語', en: 'スワヒリ語' },
  swe: { ja: 'スウェーデン語', en: 'スウェーデン語' },
  syc: { ja: '古典シリア語', en: '古典シリア語' },
  syr: { ja: 'シリア語', en: 'シリア語' },
  tah: { ja: 'タヒチ語', en: 'タヒチ語' },
  tai: { ja: 'タイ諸語(その他)', en: 'タイ諸語(その他)' },
  tam: { ja: 'タミル語', en: 'タミル語' },
  tat: { ja: 'タタール語', en: 'タタール語' },
  tel: { ja: 'テルグ語', en: 'テルグ語' },
  tem: { ja: 'テムネ語', en: 'テムネ語' },
  ter: { ja: 'テレーノ語', en: 'テレーノ語' },
  tet: { ja: 'テトゥン語', en: 'テトゥン語' },
  tgk: { ja: 'タジク語', en: 'タジク語' },
  tgl: { ja: 'タガログ語', en: 'タガログ語' },
  tha: { ja: 'タイ語', en: 'タイ語' },
  tib: { ja: 'チベット語', en: 'チベット語' },
  bod: { ja: 'チベット語', en: 'チベット語' },
  tig: { ja: 'ティグレ語', en: 'ティグレ語' },
  tir: { ja: 'ティグリニア語', en: 'ティグリニア語' },
  tiv: { ja: 'ティブ語', en: 'ティブ語' },
  tkl: { ja: 'トケラウ語', en: 'トケラウ語' },
  tlh: { ja: 'クリンゴン語', en: 'クリンゴン語' },
  tli: { ja: 'トリンギット語', en: 'トリンギット語' },
  tmh: { ja: 'トゥアレグ語', en: 'トゥアレグ語' },
  tog: { ja: 'トンガ語(ニヤサ地方)', en: 'トンガ語(ニヤサ地方)' },
  ton: { ja: 'トンガ語(トンガ諸島)', en: 'トンガ語(トンガ諸島)' },
  tpi: { ja: 'トク・ピシン', en: 'トク・ピシン' },
  tsi: { ja: 'ツィムシアン語', en: 'ツィムシアン語' },
  tsn: { ja: 'ツワナ語', en: 'ツワナ語' },
  tso: { ja: 'ツォンガ語', en: 'ツォンガ語' },
  tuk: { ja: 'トルクメン語', en: 'トルクメン語' },
  tum: { ja: 'トゥンブカ語', en: 'トゥンブカ語' },
  tup: { ja: 'トゥピ語族', en: 'トゥピ語族' },
  tur: { ja: 'トルコ語', en: 'トルコ語' },
  tut: { ja: 'アルタイ諸言語(その他)', en: 'アルタイ諸言語(その他)' },
  tvl: { ja: 'ツバル語', en: 'ツバル語' },
  twi: { ja: 'チュイ語', en: 'チュイ語' },
  tyv: { ja: 'トゥワ語', en: 'トゥワ語' },
  udm: { ja: 'ウドムルト語', en: 'ウドムルト語' },
  uga: { ja: 'ウガリト語', en: 'ウガリト語' },
  uig: { ja: 'ウイグル語', en: 'ウイグル語' },
  ukr: { ja: 'ウクライナ語', en: 'ウクライナ語' },
  umb: { ja: 'ウンブンドゥ語', en: 'ウンブンドゥ語' },
  und: { ja: '不明言語', en: '不明言語' },
  urd: { ja: 'ウルドゥー語', en: 'ウルドゥー語' },
  uzb: { ja: 'ウズベク語', en: 'ウズベク語' },
  vai: { ja: 'バイ語', en: 'バイ語' },
  ven: { ja: 'ベンダ語', en: 'ベンダ語' },
  vie: { ja: 'ベトナム語', en: 'ベトナム語' },
  vol: { ja: 'ボラピューク語', en: 'ボラピューク語' },
  vot: { ja: 'ボート語', en: 'ボート語' },
  wak: { ja: 'ワカシュ語族', en: 'ワカシュ語族' },
  wal: { ja: 'ワッラモ語', en: 'ワッラモ語' },
  war: { ja: 'ワライ語', en: 'ワライ語' },
  was: { ja: 'ワショ語', en: 'ワショ語' },
  wel: { ja: 'ウェールズ語', en: 'ウェールズ語' },
  cym: { ja: 'ウェールズ語', en: 'ウェールズ語' },
  wen: { ja: 'ソルブ語', en: 'ソルブ語' },
  wln: { ja: 'ワロン語', en: 'ワロン語' },
  wol: { ja: 'ウォロフ語', en: 'ウォロフ語' },
  xal: { ja: 'カルムイク語；オイラト語', en: 'カルムイク語；オイラト語' },
  xho: { ja: 'コサ語', en: 'コサ語' },
  yao: { ja: 'ヤオ語', en: 'ヤオ語' },
  yap: { ja: 'ヤップ語', en: 'ヤップ語' },
  yid: { ja: 'イディッシュ語', en: 'イディッシュ語' },
  yor: { ja: 'ヨルバ語', en: 'ヨルバ語' },
  ypk: { ja: 'ユピック語', en: 'ユピック語' },
  zap: { ja: 'サポテック語', en: 'サポテック語' },
  zbl: { ja: '', en: '' },
  zen: { ja: 'ゼナガ語', en: 'ゼナガ語' },
  zha: { ja: 'チュワン語', en: 'チュワン語' },
  znd: { ja: 'ザンデ語', en: 'ザンデ語' },
  zul: { ja: 'ズールー語', en: 'ズールー語' },
  zun: { ja: 'ズニ語', en: 'ズニ語' },
  zxx: { ja: '言語的内容なし', en: '言語的内容なし' },
  zza: { ja: 'ザザキ語', en: 'ザザキ語' },
}

export default languageLabel

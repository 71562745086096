import { Bucket } from '@/data/@types/Aggregations'

export class FacetItem {
  name: string;
  buckets: Bucket[];
  constructor (name: string, buckets: Bucket[]) {
    this.name = name
    this.buckets = buckets
  }
}

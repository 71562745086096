
import { defineComponent, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import StringKeyObject from '@/data/@types/StringKeyObject'
import AppButton from '@/components/atoms/AppButton.vue'
import toLocale from '@/helpers/util/toLocale'
import { useI18n } from 'vue-i18n'
import ButtonIcon from '../../../molecules/ButtonIcon.vue'

type State = {
  ariaSelected: boolean;
};
export default defineComponent({
  name: 'FacetPanel',
  components: {
    CloserController,
    AppButton,
    ButtonIcon,
  },
  props: {
    from: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive<State>({
      ariaSelected: true,
    })

    const query = reactive({
      fromYear_facet: computed(() => route.query.fromYear_facet).value,
      toYear_facet: computed(() => route.query.toYear_facet).value,
    })

    const deleteRemoveItem = (pid: string) => {
      store.commit('DELETE_REMOVEITEM_LIST', pid)
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
        excludes_pid_list: removeItemPidList.value,
      }

      router.push({ query: where })
    }

    const clear = () => {
      const where: StringKeyObject = {
        ...route.query,
        ...query,
        pageNum: '0',
      }
      delete where.excludes_pid_list
      router.push({ query: where })
    }

    const removeItems = computed(() => store.getters.RemoveItems)

    const removeItemPidList = computed(() => {
      const pidList: string[] = []
      const removeItems = store.getters.RemoveItems
      removeItems.forEach((v: any) => pidList.push(v.pid))
      return pidList
    })

    return {
      route,
      state,
      query,
      clear,
      toLocale,
      lang,
      removeItems,
      deleteRemoveItem,
    }
  },
})

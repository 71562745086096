import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c307e93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-search-result-left-column" }
const _hoisted_2 = { class: "the-search-result-left-column-header level is-mobile" }
const _hoisted_3 = {
  key: 0,
  class: "is-hidden-mobile is-sr-only"
}
const _hoisted_4 = {
  key: 1,
  class: "is-hidden-desktop has-text-contents-title has-text-modest"
}
const _hoisted_5 = { class: "the-search-result-left-column-clear level" }
const _hoisted_6 = { class: "focus-move-button-group" }
const _hoisted_7 = {
  key: 0,
  class: "facet-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isVisibleFilteringLabel)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.$t('parts.facetFiltering')), 1))
        : _createCommentVNode("", true),
      (_ctx.isVisibleFilteringLabel)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$t('parts.facetFiltering')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AppButton, {
          hidden: !_ctx.isNarrowed,
          onClick: _ctx.resetNarrowingDown,
          onKeydown: _withKeys(_withModifiers(_ctx.resetNarrowingDown, ["prevent"]), ["space","enter"]),
          styled: "is-none",
          class: "has-text-link the-search-result-left-column-clear-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('label.clearSelect')), 1)
          ]),
          _: 1
        }, 8, ["hidden", "onClick", "onKeydown"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_AppButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focusMove())),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.focusMove()), ["prevent"]), ["space","enter"])),
        id: "facet-focus-move-button-of-search-result",
        class: "focus-move-button",
        styled: "is-accent",
        tabindex: "0"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.shortcut.facet.facetFocusMoveButtonOfSearchResult')), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.totalHits != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), { facetContents: _ctx.facetContents }, null, 8, ["facetContents"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
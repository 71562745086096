
import { defineComponent, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CloserController from '@/components/molecules/CloserController.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'

type State = {
  ariaSelected: boolean;
};
export default defineComponent({
  name: 'FacetKeyword',
  components: {
    CloserController,
    AppButton,
    KeywordSearch,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()

    const state = reactive<State>({
      ariaSelected: true,
    })

    const query = reactive({
      keyword: route.query.keyword,
    })

    const search = () => {
      if (route.name) {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            keyword: query.keyword,
          },
        })
      }
    }

    return {
      route,
      state,
      query,
      search,
    }
  },
})
